import getConfig from 'next/config'
import languageCodeJson from 'localize/languageCodeLookup.json'

const { publicRuntimeConfig } = getConfig() || { publicRuntimeConfig: {} }
export const productTitle = 'Healthwise Algolia Demo'
export const defaultOrganizationName = `Healthwise`
export const thisYear = new Date().getFullYear()
export const productCopyrightYearSpan = `2022-${thisYear}`

export const theUnPatientId = 'Not a patient'
export const noFirstNamePlaceholder = '__NO_FIRST_NAME__'

export const videoAspectRatio = 1.7777778 // 16/9
export const paperAspectRatio = 0.7727273 // 8½/11

export const maxNumberSearchResults = 100

export const defaultLanguageCode: __Language = 'en'
export const defaultCountryCode: __Country = 'us'
export const defaultLocalization: __Localization = `${defaultLanguageCode}-${defaultCountryCode}`
export const rtlLanguages = ['ar', 'fa']
export const allLocalizations = [
  'en-us',
  'es-us',
  'en-ca',
  'fr-ca',
  'fr-us',
  'da-dk',
  'ar-us',
  'bs-us',
  'fa-us',
  'ht-us',
  'hm-us',
  'ko-us',
  'pt-us',
  'ru-us',
  'so-us',
  'sw-us',
  'tl-us',
  'vi-us',
  'zh-us',
  'hy-us',
  'km-us',
  'pl-us',
  'bn-us',
  'it-us',
  'ne-us',
]

export const getLangFromLoc = (localization: string | __Localization | undefined): __Language => {
  if (localization) {
    const langCode = languageCodeJson.find(code => code.localization === localization)
    if (langCode?.lang) {
      return langCode.lang as __Language
    }
    return localization.substring(0, 2) as __Language
  }
  return defaultLanguageCode
}

export const supportedCountryCodes = ['us', 'ca', 'mx']
export const supportedLangCodes = allLocalizations.map(loc => getLangFromLoc(loc))
export const dmyDateFormatCountries = ['ca', 'mx']
export const mdyDateFormatCountries = ['us']
export const displayLocalizations: __Localization[] = ['en-us', 'es-us']
export const latestDisplayLocalizations: __Localization[] = ['en-us', 'es-us', 'zh-us']
export const currentDocumentVersion = 'CURRENT'
export const hwApiVersion = '1'
export const minVideoCompletePercentageToTrack = 75

export const searchHitsPerPage = 20

export const loremIpsum =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
export const defaultTestUrl =
  'https://learn.test.hwapps.net/content/tw12205/en-us/893c2cd6-ea5a-4688-bcde-1e3be9d357b2?patientId=hw.default.test.id&pracId=133&orgId=ePqMmiCU9E1xOUefB9e7Plw3&origin=patientPortal&format=text'
export const genericBrandingAlt = 'Generic Health Logo'

export const securityCodeLength = 6
export const medicalDateFormat = 'DD MMM YYYY'
export const medicalDateDayFormat = 'DD'
export const medicalDateMonthFormat = 'MMM'
export const medicalDateYearFormat = 'YYYY'
export const medicalDateFormatLong = 'DD MMM YYYY h:mm a'

export const keyedRoutes = ['authentication', 'content', 'features']

export const authTestExpireInterval = 10000

type AppEnvSettings = {
  baseUrl: string
  apiBaseUrl: string
  epicAuthUrl: string
}

export const envSettings: AppEnvSettings = {
  baseUrl: publicRuntimeConfig.BASE_URL || '',
  apiBaseUrl: publicRuntimeConfig.APP_API_URL || '',
  epicAuthUrl: publicRuntimeConfig.EPIC_AUTH_URL || '',
}

export const getEnvironment = () => {
  const env = publicRuntimeConfig.REACT_APP_ENVIRONMENT
  const environment = {
    dev: env === 'dev',
    test: env === 'test',
    demo: env === 'demo',
    prod: env === 'prod' || env === 'production',
  }
  return environment
}

export const algoliaSearchSettings: __SearchContextData = {
  applicationId: publicRuntimeConfig.ALGOLIA_APPLICATION_ID || '',
  discoApi: publicRuntimeConfig.DISCO_API || '',
  discoAppId: 'consumner-ui-app',
}

export const mediaSettings = {
  mediaServiceUrl: publicRuntimeConfig.MEDIA_SERVICE_URL,
  mediaHash: publicRuntimeConfig.MEDIA_SERVICE_HASH,
  mediaToken: publicRuntimeConfig.MEDIA_SERVICE_TOKEN,
}

const appSettingsKey = 'app_config_settings'

type AppSettingKeys = 'patientId' | 'maskedPhoneNumber' | 'maskedEmail'
export const setAppSetting = (settingName: AppSettingKeys, setting: string | boolean) => {
  if (settingName && typeof window !== 'undefined') {
    const appSettings = JSON.parse(sessionStorage.getItem(appSettingsKey) || '{}')
    appSettings[settingName] = setting
    sessionStorage.setItem(appSettingsKey, JSON.stringify(appSettings))
  }
}

export const getAppSetting = (settingName: AppSettingKeys, fallback: any = {}) => {
  if (typeof window !== 'undefined') {
    const appSettings = JSON.parse(sessionStorage.getItem(appSettingsKey) || '{}')
    return appSettings[settingName]
  }
  return fallback
}

export const clearAppSetting = (settingName: AppSettingKeys) => {
  if (typeof window !== 'undefined') {
    const appSettings = JSON.parse(sessionStorage.getItem(appSettingsKey) || '{}')
    if (appSettings[settingName]) {
      delete appSettings[settingName]
    }
    sessionStorage.setItem(appSettingsKey, JSON.stringify(appSettings))
  }
}

export const purgeAppSettings = () => {
  if (typeof window !== 'undefined') {
    sessionStorage.removeItem(appSettingsKey)
  }
}
