import {
  FetchTransport,
  initializeFaro as coreInit,
  getWebInstrumentations,
} from '@grafana/faro-web-sdk'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig() || { publicRuntimeConfig: {} }

export const initializeFaro = () => {
  const faro = coreInit({
    // Mandatory, the identification label(s) of your application
    app: {
      name: publicRuntimeConfig.K8S_APP_NAME, // Required
      version: publicRuntimeConfig.APP_VERSION, // Optional, but recommended
      environment: publicRuntimeConfig.REACT_APP_ENVIRONMENT, // Optional, but recommended
      release: publicRuntimeConfig.APP_COMMIT_HASH, // Optional, but recommended
    },
    instrumentations: [...getWebInstrumentations()],
    transports: [
      new FetchTransport({
        // Mandatory, the URL of the telemetry collector
        url: publicRuntimeConfig.K8S_TELEMETRY_COLLECTOR_URL,
        // Mandatory, the API key of the telemetry collector
        apiKey: publicRuntimeConfig.TELEMETRY_COLLECTOR_API_KEY,
      }),
    ],
  })

  faro?.api.pushLog(['Faro was initialized'])
  return faro
}
