import { cloneDeep } from 'lodash'
import { maxUserAge, minUserAge, attributeLabels } from './filter.config'

const orJoiner = ' OR '
const andJoiner = ' AND '

export type FilterStringBuilderSettings = {
  onChange?: OnChangeFunctionType
  age?: number
  leftAge?: number
  rightAge?: number
  audience?: string
  audiences?: string[]
  concept?: string
  concepts?: string[]
  sex?: string
  sexes?: string[]
  type?: string
  types?: string[]
  delivery?: string
  deliveries?: string[]
  specialty?: string
  specialties?: string[]
  localization?: string
  localizations?: string[]
  product?: string
  products?: string[]
  id?: string
  ids?: string[]
  hwid?: string
  hwids?: string[]
  debug?: boolean
  displayLanguage?: string
  interactiveContent?: string
  assetType?: string
}

export type OnChangeFunctionSettings = {
  aisFilterString: string
  aisFilterList: string[]
  optionalFilterList: string[]
  isInDefaultState: boolean
  activeFilterCount: number
}

export type FilterType = {
  attributeLabel?: string | undefined
  attributeLabels?: (string | number | undefined)[] | undefined
  setItem:
    | ((itemsCandidate: (string | number | undefined)[] | string | number | undefined) => void)
    | ((age: number | string | undefined, attribute: string | undefined) => void)
  item?: string | number | undefined
  items?: (string | number | undefined)[]
  debug?: boolean | undefined
  age?: number | string | undefined
  leftAge?: number | string | undefined
  rightAge?: number | string | undefined
  reset: (attributeLabel?: string) => void
  getFilterString: () => string
  getLabelList: () => string[]
}

export type OnChangeFunctionType = (settings: OnChangeFunctionSettings) => any

export const getFilterStringBuilder = (settings?: FilterStringBuilderSettings) => {
  return new FilterStringBuilder(settings)
}

export const filterLabelCompose = (
  attribute: string | undefined,
  label?: string | number | undefined,
  addQuotes = false,
): string => {
  if (attribute && label !== undefined) {
    if (addQuotes || typeof label === 'string') {
      return `${attribute}:'${label}'`
    }
    return `${attribute}:${label}`
  }
  return ''
}

const defaultFilterValues = [
  filterLabelCompose(attributeLabels.leftAge, minUserAge),
  filterLabelCompose(attributeLabels.rightAge, maxUserAge),
]

const buildOrFilterString = (
  itemList: (string | number | undefined)[],
  itemLabel: string | undefined,
): string => {
  if (itemList?.length > 0) {
    const items: string[] = []
    itemList.forEach(item => items.push(filterLabelCompose(itemLabel, item)))
    const orFilter = items.length > 0 ? `(${items.join(orJoiner)})` : ''
    return orFilter
  }
  return ''
}

const getFilterLabelsList = (
  itemList: (string | number | undefined)[],
  itemLabel: string | undefined,
): string[] => {
  const aisFilterList: string[] = []
  if (itemList?.length > 0) {
    itemList.forEach(item => aisFilterList.push(filterLabelCompose(itemLabel, item)))
  }
  return aisFilterList
}

export const areFiltersInDefaultState = (selectedFilters: string[]) => {
  if (Array.isArray(selectedFilters) && selectedFilters.length === defaultFilterValues.length) {
    if (
      selectedFilters[0] === defaultFilterValues[0] &&
      selectedFilters[1] === defaultFilterValues[1]
    ) {
      return true
    }
    if (
      selectedFilters[1] === defaultFilterValues[0] &&
      selectedFilters[0] === defaultFilterValues[1]
    ) {
      return true
    }
  }

  return false
}

export const getActiveFilterCount = (selectedFilters: string[]) => {
  if (Array.isArray(selectedFilters)) {
    const filters = cloneDeep(selectedFilters)
    defaultFilterValues.forEach(filterValue => {
      const filterIndex = filters.findIndex(filter => filter === filterValue)
      if (filterIndex !== -1) {
        filters.splice(filterIndex, 1)
      }
    })
    return filters.length
  }

  return 0
}

export const filterStringParse = (filterString = ''): string[] => {
  const aisFilterList: string[] = []

  let filter = filterString.replace(/[()]/g, '').replace(/ >= /, ':').replace(/ <= /, ':')
  const andSplit = filter.split(andJoiner)
  andSplit.forEach(andSplitItem => {
    if (andSplitItem.includes(orJoiner)) {
      const orSplit = andSplitItem.split(orJoiner)
      orSplit.forEach(orSplitItem => {
        aisFilterList.push(orSplitItem.trim())
      })
    } else {
      aisFilterList.push(andSplitItem.trim())
    }
  })
  return aisFilterList
}

export const filterLabelParse = (filterLabel: string | number | undefined) => {
  if (filterLabel !== undefined && `${filterLabel}`.includes(':')) {
    const filterParts = `${filterLabel}`.split(':')
    const attribute = filterParts[0]
    let label = filterParts[1].replace(/'/g, '')
    const numberLabel = parseInt(label)
    if (!isNaN(numberLabel)) {
      label = `${numberLabel}`
    }
    return { attribute, label }
  }
  return { attribute: '', label: '' }
}

const isValidAttribute = (attribute: string | undefined) => {
  let valid = false
  for (let key in attributeLabels) {
    if (attribute === attributeLabels[key]) {
      valid = true
      break
    }
  }
  return valid
}

export class FilterStringBuilder {
  onChange: OnChangeFunctionType | undefined
  isInDefaultState = true
  isInitialized = false
  aisFilterString = ''
  aisFilterList: string[] = []
  optionalFilterList: string[] = []
  filters: FilterType[] = []
  debug = false
  activeFilterCount = 0

  constructor(settings: FilterStringBuilderSettings = {}) {
    this.onChange = settings.onChange

    const filters: FilterType[] = []
    const {
      age,
      audience,
      audiences,
      leftAge,
      rightAge,
      concepts,
      concept,
      sex,
      sexes,
      type,
      types,
      delivery,
      deliveries,
      specialty,
      specialties,
      localization,
      localizations,
      product,
      products,
      id,
      ids,
      hwid,
      hwids,
      debug,
      displayLanguage,
      interactiveContent,
      assetType,
    } = settings

    //Warning: changing the order here will probably break tests
    filters.push(new AgeFilter({ age, rightAge, leftAge, debug }))
    filters.push(new AudienceFilter({ item: audience, items: audiences, debug }))
    filters.push(new SexFilter({ item: sex, items: sexes, debug }))
    filters.push(new ConceptFilter({ item: concept, items: concepts, debug }))
    filters.push(new TypeFilter({ item: type, items: types, debug }))
    filters.push(new DeliveryFilter({ item: delivery, items: deliveries, debug }))
    filters.push(new SpecialtyFilter({ item: specialty, items: specialties, debug }))
    filters.push(new LocalizationFilter({ item: localization, items: localizations, debug }))
    filters.push(new ProductFilter({ item: product, items: products, debug }))
    filters.push(new KeywordsOneFilter({}))
    filters.push(new KeywordsTwoFilter({}))
    filters.push(new IdFilter({ item: id, items: ids }))
    filters.push(new HwidFilter({ item: hwid, items: hwids }))
    filters.push(new DisplayLanguageFilter({ item: displayLanguage, debug }))
    filters.push(new InteractiveContentFilter({ item: interactiveContent, debug }))
    filters.push(new AssetFilter({ item: assetType, debug }))

    this.filters = filters
    this.debug = !!settings.debug
    this.build()
  }

  public reset(attributeLabel?: string) {
    if (attributeLabel) {
      this.filters.forEach(filter => {
        if (
          filter.attributeLabel === attributeLabel ||
          filter.attributeLabels?.includes(attributeLabel)
        ) {
          filter.reset(attributeLabel)
        }
      })
    } else {
      this.aisFilterString = ''
      this.aisFilterList = []
      this.filters.forEach(filter => {
        filter.reset()
      })
      this.isInitialized = false
    }
    this.build()
  }

  public initialize(filters?: (string | number | undefined)[] | string) {
    if (Array.isArray(filters)) {
      this.reset()
      filters.forEach(filter => {
        const { attribute, label } = filterLabelParse(filter)
        this.update(attribute, label)
      })
      this.isInitialized = true
    } else if (typeof filters === 'string') {
      const aisFilterList = filterStringParse(filters)
      this.initialize(aisFilterList)
    }
  }

  public merge(
    mergeFilters?: (string | number | undefined)[] | string,
    mergeAttributes?: string[] | undefined,
    replaceAttributes?: string[] | undefined,
  ) {
    // If filter string parse it and try again
    if (typeof mergeFilters === 'string') {
      const aisFilterList = filterStringParse(mergeFilters)
      this.merge(aisFilterList, mergeAttributes, replaceAttributes)
    }

    // Duplicate attributes causes problems
    if (Array.isArray(mergeAttributes) && Array.isArray(replaceAttributes)) {
      const duplicates = mergeAttributes.filter(attr => {
        return replaceAttributes.includes(attr)
      })
      if (duplicates.length > 0) {
        throw new Error(
          `Duplicate attributes found in merge attributes and replace attributes: '${duplicates.join(
            "', '",
          )}''`,
        )
      }
    }

    // Add merge attributes with existing attributes
    if (Array.isArray(mergeFilters) && Array.isArray(mergeAttributes)) {
      mergeFilters.forEach(mergeFilter => {
        const { attribute: mergeAttribute, label: mergeLabel } = filterLabelParse(mergeFilter)
        const filterIsActive = this.testFilterActive(mergeAttribute, mergeLabel)
        if (!filterIsActive && mergeAttributes.includes(mergeAttribute)) {
          this.debug && console.info('adding', mergeAttribute, mergeLabel)
          this.add(mergeAttribute, mergeLabel)
        }
      })
    }

    if (Array.isArray(mergeFilters) && Array.isArray(replaceAttributes)) {
      // Remove filters missing from merge filters
      replaceAttributes.forEach(requiredAttribute => {
        this.debug && console.info('removing', requiredAttribute)
        this.reset(requiredAttribute)
      })

      // Add new merge filters
      mergeFilters.forEach(mergeFilter => {
        const { attribute: mergeAttribute, label: mergeLabel } = filterLabelParse(mergeFilter)
        if (replaceAttributes.includes(mergeAttribute)) {
          this.debug && console.info('replacing', mergeAttribute, mergeLabel)
          this.add(mergeAttribute, mergeLabel)
        }
      })
    }
  }

  public setDebug(debugOn: boolean) {
    this.debug = debugOn
  }

  public add(
    attribute: string | undefined,
    labelsCandidate: string | number | undefined | (string | number | undefined)[],
  ) {
    this.update(attribute, labelsCandidate, 'add')
  }

  public remove(
    attribute: string | undefined,
    labelsCandidate?: string | number | undefined | (string | number | undefined)[],
  ) {
    this.update(attribute, labelsCandidate, 'remove')
  }

  public update(
    attribute: string | undefined,
    labelsCandidate?: string | number | undefined | (string | number | undefined)[],
    modifier?: 'add' | 'remove' | undefined,
  ) {
    const isAgeAttribute = [attributeLabels.leftAge, attributeLabels.rightAge].includes(
      attribute || '',
    )

    if (!attribute) {
      throw new Error(
        `Update failed - no attribute supplied for label(s): '${labelsCandidate || ''}''`,
      )
    }
    if (!labelsCandidate && labelsCandidate !== 0 && !(isAgeAttribute || modifier === 'remove')) {
      throw new Error(`No label for attribute: '${attribute}'`)
    }
    if (!isValidAttribute(attribute)) {
      throw new Error(`Update failed - invalid attribute: ${attribute}`)
    }

    let found = false
    let labels = Array.isArray(labelsCandidate) ? labelsCandidate : [labelsCandidate]
    this.filters.forEach(filter => {
      if (
        !found &&
        (filter.attributeLabel === attribute ||
          (Array.isArray(filter.attributeLabels) && filter.attributeLabels.includes(attribute)))
      ) {
        found = true

        labels.forEach(label => {
          if (modifier === 'add') {
            if (isAgeAttribute) {
              filter.setItem(label, attribute)
            } else if (!filter.items?.includes(label)) {
              filter.setItem(label, attribute)
            }
          } else if (modifier === 'remove') {
            if (isAgeAttribute || typeof label === 'undefined') {
              filter.reset(attribute)
            } else if (filter.items?.includes(label)) {
              filter.setItem(label, attribute)
            }
          } else {
            filter.setItem(label, attribute)
          }
        })
      }
    })
    if (!found) {
      console.error('No case with attribute: ', attribute)
    }
    this.build()
  }

  testFilterActive(attribute: string | undefined, label?: string | number | undefined): boolean {
    let found = false
    this.filters.forEach(filter => {
      if (
        !found &&
        (filter.attributeLabel === attribute ||
          (Array.isArray(filter.attributeLabels) && filter.attributeLabels.includes(attribute)))
      ) {
        if (attributeLabels.leftAge === attribute) {
          found = found || parseInt(`${filter?.leftAge || minUserAge}`, 10) > minUserAge
        } else if (attributeLabels.rightAge === attribute) {
          found = found || parseInt(`${filter?.rightAge || maxUserAge}`, 10) < maxUserAge
        } else if (filter.items && label) {
          found = found || filter.items.includes(label)
        } else if (!label) {
          found = found || (filter.items?.length || 0) > 0
        }
      }
    })

    return found
  }

  getFilterByAttribute(attribute: string | undefined): (string | number | undefined)[] {
    let found: (string | number | undefined)[] = []
    this.filters.forEach(filter => {
      if (
        filter.attributeLabel === attribute ||
        (Array.isArray(filter.attributeLabels) && filter.attributeLabels.includes(attribute))
      ) {
        if (
          attributeLabels.leftAge === attribute &&
          parseInt(`${filter?.leftAge || minUserAge}`, 10) > minUserAge
        ) {
          found.push(`${filter.leftAge}`)
        } else if (
          attributeLabels.rightAge === attribute &&
          parseInt(`${filter?.rightAge || maxUserAge}`, 10) < maxUserAge
        ) {
          found.push(`${filter.rightAge}`)
        } else if (filter?.items) {
          filter.items.forEach(item => {
            found.push(`${item}`)
          })
        }
      }
    })

    return found
  }

  build() {
    const nonConceptFilterStrings: string[] = []
    let conceptFilterString = ''
    let aisFilterList: string[] = []

    this.filters.forEach(filter => {
      const filterString = filter.getFilterString()
      if (filterString) {
        if (filter.attributeLabel === attributeLabels.concepts) {
          conceptFilterString = conceptFilterString
            ? `${conceptFilterString} AND ${filterString}`
            : filterString
        } else {
          nonConceptFilterStrings.push(filterString)
        }
      }

      const labelList = filter.getLabelList()
      if (Array.isArray(labelList)) {
        aisFilterList = aisFilterList.concat(labelList)
      } else if (labelList && typeof labelList === 'string') {
        aisFilterList.push(labelList)
      }
    })

    this.aisFilterList = aisFilterList
    this.aisFilterString = conceptFilterString

    const nonConceptFilter = nonConceptFilterStrings.join(andJoiner)
    if (nonConceptFilter) {
      if (conceptFilterString) {
        this.aisFilterString = `${conceptFilterString} AND ${nonConceptFilter}`
      } else {
        this.aisFilterString = nonConceptFilter
      }
    }

    this.isInDefaultState = areFiltersInDefaultState(aisFilterList)
    this.activeFilterCount = getActiveFilterCount(aisFilterList)

    const optionalFiltersToIgnore = [
      `${attributeLabels.leftAge}:${minUserAge}`,
      `${attributeLabels.rightAge}:${maxUserAge}`,
    ]

    this.optionalFilterList = this.aisFilterList.filter(
      filterString => !optionalFiltersToIgnore.includes(filterString),
    )

    if (this.debug) {
      console.info({
        aisFilterList: this.aisFilterList,
        optionalFilterList: this.optionalFilterList,
        aisFilterString: this.aisFilterString,
        isInDefaultState: this.isInDefaultState,
        activeFilterCount: this.activeFilterCount,
      })
    }

    if (typeof this.onChange === 'function') {
      this.onChange({
        aisFilterList: this.aisFilterList,
        optionalFilterList: this.optionalFilterList,
        aisFilterString: this.aisFilterString,
        isInDefaultState: this.isInDefaultState,
        activeFilterCount: this.activeFilterCount,
      })
    }
  }
}

type AgeFilterSettingsType = {
  debug: boolean | undefined
  age?: number | string | undefined
  leftAge?: number | string | undefined
  rightAge?: number | string | undefined
}

class AgeFilter {
  debug = false
  attributeLabel = undefined
  attributeLabels: (string | number | undefined)[]
  leftAge = minUserAge
  rightAge = maxUserAge

  constructor(settings: AgeFilterSettingsType) {
    this.debug = !!settings.debug
    this.attributeLabels = [attributeLabels.rightAge, attributeLabels.leftAge]
    this.setItem(
      (parseInt(`${settings.age}`, 10) || -1) >= 0
        ? settings.age
        : (parseInt(`${settings.rightAge}`, 10) || -1) >= 0
        ? settings.rightAge
        : maxUserAge,
      attributeLabels.rightAge,
    )
    this.setItem(
      settings.age || -1 >= 0
        ? settings.age
        : settings.leftAge || -1 >= 0
        ? settings.leftAge
        : minUserAge,
      attributeLabels.leftAge,
    )
  }

  setItem(age: number | string | undefined, attribute: string | undefined) {
    age = typeof age === 'number' ? age : parseInt(age || '0', 10)
    const ageKey = attribute === attributeLabels.rightAge ? 'rightAge' : 'leftAge'
    if (age >= minUserAge && age <= maxUserAge) {
      this[ageKey] = age
    } else if (age < minUserAge) {
      this[ageKey] = minUserAge
    } else if (age > maxUserAge) {
      this[ageKey] = maxUserAge
    }
  }

  getFilterString() {
    const filterStrings = []
    const isLeftAgeLegit = typeof this.leftAge === 'number' && this.leftAge !== minUserAge
    const isRightAgeLegit = typeof this.rightAge === 'number' && this.rightAge !== maxUserAge

    if (isLeftAgeLegit) {
      filterStrings.push(`${attributeLabels.leftAge} >= ${this.leftAge}`)
    }
    if (isRightAgeLegit) {
      filterStrings.push(`${attributeLabels.rightAge} <= ${this.rightAge}`)
    }

    if (filterStrings.length > 0) {
      return `(${filterStrings.join(andJoiner)})`
    }

    return ''
  }

  getLabelList(): string[] {
    const labelList: string[] = []
    if (typeof this.leftAge === 'number') {
      labelList.push(filterLabelCompose(attributeLabels.rightAge, this.rightAge))
    }
    if (typeof this.rightAge === 'number') {
      labelList.push(filterLabelCompose(attributeLabels.leftAge, this.leftAge))
    }

    return labelList
  }

  reset(attribute?: string | number | undefined) {
    if (attribute === attributeLabels.rightAge) {
      this.rightAge = maxUserAge
    } else if (attribute === attributeLabels.leftAge) {
      this.leftAge = minUserAge
    } else {
      this.leftAge = minUserAge
      this.rightAge = maxUserAge
    }
  }
}

type BaseFilterType = {
  item?: string | number | undefined
  items?: (string | number | undefined)[]
  debug?: boolean | undefined
}

class BaseFilter {
  item: string | number | undefined
  items: (string | number | undefined)[]
  attributeLabel: string | undefined
  attributeLabels: undefined

  constructor(settings: BaseFilterType = {}) {
    this.items = []
    if (Array.isArray(settings.items)) {
      this.setItem(settings.items)
    } else {
      this.setItem(settings.item)
    }
  }

  setItem(itemsCandidate: (string | number | undefined)[] | string | number | undefined) {
    if (itemsCandidate || itemsCandidate === 0) {
      const items = Array.isArray(itemsCandidate) ? itemsCandidate : [itemsCandidate]
      items.forEach(ic => {
        const item = this.normalize(ic)
        const index = this.items.findIndex(storedItem => storedItem === item)
        if (index === -1) {
          this.items.push(item)
        } else {
          this.items.splice(index, 1)
        }
      })
    }
  }

  normalize(itemsCandidate: string | number | undefined): string | number | undefined {
    return itemsCandidate
  }

  reset() {
    this.items = []
  }

  getFilterString() {
    return buildOrFilterString(this.items, this.attributeLabel)
  }

  getLabelList() {
    return getFilterLabelsList(this.items, this.attributeLabel)
  }
}

class SexFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.sex
  }

  normalize(itemsCandidate: string | number | undefined): string | number | undefined {
    const candidate = `${itemsCandidate}`.toLowerCase()
    if (candidate.startsWith('m')) {
      return 'Male'
    }
    if (candidate.startsWith('f')) {
      return 'Female'
    }
    return itemsCandidate
  }
}

class ConceptFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.concepts
  }

  getFilterString() {
    const concepts: string[] = []
    this.items.forEach(item =>
      concepts.push(
        `${attributeLabels.concepts}:'${item}'${orJoiner}${attributeLabels.keywords1}:'${item}'${orJoiner}${attributeLabels.keywords2}:'${item}'`,
      ),
    )
    return concepts.length > 0 ? `(${concepts.join(orJoiner)})` : ''
  }
}

class TypeFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.contentType
  }
}

class DeliveryFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.delivery
  }
}

class AudienceFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.audience
  }
}

class SpecialtyFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.specialty
  }
}

class LocalizationFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.localizations
  }
}

class DisplayLanguageFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.displayLanguage
  }
}

class InteractiveContentFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.interactiveContent
  }
}

class AssetFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.assetType
  }
}

class ProductFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.product
  }
}

class KeywordsOneFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.keywords1
  }

  // Ignore since we're doing this stuff in the concept filter
  getFilterString() {
    return ''
  }

  getLabelList() {
    return []
  }
}

class KeywordsTwoFilter extends KeywordsOneFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.keywords2
  }
}

class IdFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.id
  }
}

class HwidFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.hwid
  }
}
