import { FC, ReactElement } from 'react'
import dynamic from 'next/dynamic'
import SearchWrapper from 'searchContext/useSearchContext'
import MetadataContextWrapper from 'searchContext/useMetadataContext'
const AppWrapper = dynamic(() => import('context/useAppContext'), { ssr: false })
const LocalizeWrapper = dynamic(() => import('localize/useLocalizeContext'), { ssr: false })
const BrandingWrapper = dynamic(() => import('brandingContext/useBrandingContext'), {
  ssr: false,
})

interface Props {
  children: ReactElement | ReactElement[]
}

const AppContextWrapper: FC<Props> = ({ children }) => {
  return (
    <AppWrapper>
      <BrandingWrapper>
        <LocalizeWrapper>
          <MetadataContextWrapper>
            <SearchWrapper>{children}</SearchWrapper>
          </MetadataContextWrapper>
        </LocalizeWrapper>
      </BrandingWrapper>
    </AppWrapper>
  )
}

export default AppContextWrapper
