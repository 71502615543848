import getConfig from 'next/config'
import { getAppAccessToken } from 'token-utils'
import { envSettings } from 'config'
import { tryParseJSON } from 'common'
import { faro, LogLevel } from '@grafana/faro-web-sdk'

const { publicRuntimeConfig } = getConfig() || { publicRuntimeConfig: {} }

export const getFetchError = (response: Response | undefined): __FetchError => {
  let error: __FetchError

  if (response?.status) {
    error = {
      status: response?.status,
      error: response?.statusText,
    }
  } else {
    error = {
      status: 503,
      error: 'Unknown error',
    }
  }

  const errorToLog = error.error != null ? error.error : ''
  faro?.api?.pushLog([errorToLog], { level: LogLevel.ERROR })
  return error
}

export const fetchErrorHandler = (
  res: Response | undefined,
  defaultErrorMsg: string,
  setErrors: (errors: string[]) => void | undefined,
  returnType = 'json',
) => {
  if (typeof res === 'undefined') return
  if (res.ok) {
    if (returnType === 'json') return res.json()
    else if (returnType === 'blob') return res.blob()
    else if (returnType === 'text') return res.text()
  } else {
    return res.text().then(body => {
      const jsonTest = tryParseJSON(body)
      let errors = []

      // error message priority: our 401, server message, message provided by calling function, generic message
      if (res.status === 401) {
        // if it's a 401, they may have an expired token (or otherwise bad one)
        const authenticationErrorMessage =
          'Authentication expired.  Please reload the page or logout to reauthenticate.'
        errors.push(authenticationErrorMessage)
        faro?.api?.pushLog([authenticationErrorMessage], { level: LogLevel.ERROR })
      } else if (jsonTest.messages) {
        throw jsonTest.messages
      } else if (defaultErrorMsg) {
        errors.push(defaultErrorMsg)
        faro?.api?.pushLog([defaultErrorMsg], { level: LogLevel.ERROR })
      } else {
        errors.push('There was an error.  Please reload and try again.')
      }
      // pass the error on to the "setError" function, if one was provided
      setErrors?.(errors)
      const allErrors = errors.join('; ')
      faro?.api?.pushLog([errors], { level: LogLevel.ERROR })
      throw new Error(allErrors)
    })
  }
}

const getAPIHeaders = () => ({
  Authorization: 'Bearer ' + getAppAccessToken(),
  'Content-Type': 'application/json',
  'X-HW-Version': '1',
  'Access-Control-Allow-Origin': envSettings.apiBaseUrl,
})

const customFetch = (
  url: string,
  obj: RequestInit = {},
  params: { [x: string]: string }[] = [],
): Promise<Response | undefined> => {
  const startTime = new Date().getTime()
  if (typeof window === 'undefined') return new Promise(resolve => resolve(undefined))

  const urlParts = url.split('?')
  let paramsString = ''

  // if the incoming URL string already has query params (denoted by "?")
  if (urlParts && urlParts[1]) {
    // set the URL to the base URL, sans query params (as we deal with those separately)
    url = urlParts[0]

    urlParts[1].split('&').forEach(param => {
      const paramParts = param.split('=')
      const key = paramParts[0]
      const val = paramParts[1] || ''

      params?.push({ [key]: val })
    })
  }

  // convert params array of objects to string
  if (params && params.length) {
    paramsString = params
      .map(param => {
        const key = Object.keys(param)[0]
        return key + '=' + param[key]
      })
      .join('&')

    paramsString = paramsString
  }

  obj.headers = {
    ...getAPIHeaders(),
    cache: 'no-store',
    ...obj.headers,
  }

  url = `${publicRuntimeConfig.APP_API_URL || envSettings.apiBaseUrl}/${url}`
  url += paramsString.length ? `?${paramsString}` : ''

  const finishTime = new Date().getTime()
  faro?.api?.pushLog([`Successfully completed custom fetch in ${finishTime - startTime} ms`], {
    level: LogLevel.ERROR,
  })
  return fetch(url, obj)
}

export default customFetch
