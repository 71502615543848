import getConfig from 'next/config'
import { ReducerStateWithoutAction } from 'react'
import is from 'is_js'
import {
  securityCodeLength,
  getEnvironment,
  supportedCountryCodes,
  supportedLangCodes,
} from 'config'

const { publicRuntimeConfig } = getConfig() || { publicRuntimeConfig: {} }
export const env = publicRuntimeConfig.REACT_APP_ENVIRONMENT

export const keys = {
  end: 'End',
  home: 'Home',
  left: 'ArrowLeft',
  up: 'ArrowUp',
  right: 'ArrowRight',
  down: 'ArrowDown',
  delete: 'Delete',
  backspace: 'Backspace',
  enter: 'Enter',
  space: ' ',
  escape: 'Escape',
  esc: 'Escape',
  tab: 'Tab',
}

export const ancestorHasId = (el: HTMLElement | null, id: string) => {
  let parent = el
  while (parent) {
    parent = parent.parentElement
    if (parent && parent.id === id) {
      return parent
    }
  }
  return undefined
}

export const getNewGuid = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${S4()}${S4()}-${S4()}-4${S4().substring(
    0,
    3,
  )}-${S4()}-${S4()}${S4()}${S4()}`.toLowerCase()
}

export const getNewDashlessGuid = () => {
  return getNewGuid().replace(/-/g, '')
}

// used for creating a faux member ID or window ID
export const getUniqueId = () => {
  return 'HW' + `${new Date().valueOf()}`.substring(5) + getNewDashlessGuid()
}

const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
export const verifyGuid = (guidCandidate: string) => {
  if (guidCandidate) {
    const isValid = guidRegex.test(guidCandidate)
    return isValid
  }
  return false
}

export const tryParseJSON = (jsonString: string) => {
  try {
    const o = JSON.parse(jsonString)
    if (o && typeof o === 'object') {
      return o
    }
  } catch (e) {}
  return false
}

export const debugLogger = (...args: any[]) => {
  const environment = getEnvironment()
  if (environment.test || environment.demo || environment.dev) {
    console['log'].apply(this, args)
  }
}

export const debugErrorLogger = (...args: any[]) => {
  const environment = getEnvironment()
  if (environment.test || environment.demo || environment.dev) {
    console['error'].apply(this, args)
  }
}

export const getContentUrl = (
  hwid: string | undefined,
  localization: __Localization,
  options?: { assignmentId?: string; contentArtifact?: string },
  queryParams?: { [key: string]: string },
) => {
  let queryString = ''
  if (queryParams) {
    Object.keys(queryParams).forEach(queryParamKey => {
      if (queryParamKey) {
        const queryParam = queryParams[queryParamKey]
        if (queryString.includes('?')) {
          queryString += '&'
        } else {
          queryString += '?'
        }
        queryString += `${queryParamKey}=${queryParam}`
      }
    })
  }

  return `/content/${hwid}/${localization}${
    options?.assignmentId ? `/${options.assignmentId}` : ''
  }${
    options?.assignmentId && options?.contentArtifact ? `/${options.contentArtifact}` : ''
  }${queryString}`
}

export const getContentUrlFromHitContent = (
  content: __AlgoliaHitContent,
  options: { assignmentId?: string; contentArtifact?: string } = {},
  queryParams: { [key: string]: string } = {},
) => {
  if (content) {
    if (content.inHouse) {
      queryParams.inHouse = 'true'
    }
    const href = getContentUrl(content.hwid, content.localization, options, queryParams)
    return href
  }
  return ''
}

const AlgoliaApplicationName = 'adviseconsumer'
export function analyticsTags(): string[] {
  let environmentName = env ?? 'dev'
  return [`environment:${environmentName.toLowerCase()}`, `application:${AlgoliaApplicationName}`]
}

export const isSecretCodeValid = (secretCode: string | undefined) => {
  return secretCode && secretCode.length === securityCodeLength && /^\d+$/.test(secretCode)
}

const langTextRegex = /[a-z]{2}|[a-z]{2}-[a-z]{2}$/

export const verifyLang = (lang: string): boolean => {
  const testLang = (lang || '').toLowerCase().replace(/ /g, '')
  if (testLang === 'hmn' || testLang === 'hm-us') {
    return true
  }
  if (testLang.match(langTextRegex)) {
    if (testLang.length === 2) {
      return supportedLangCodes.includes(testLang as __Language)
    }
    if (testLang.length === 5 && testLang.indexOf('-') === 2) {
      const langParts = testLang.split('-')
      return (
        langParts.length === 2 &&
        supportedLangCodes.includes(langParts[0] as __Language) &&
        supportedCountryCodes.includes(langParts[1])
      )
    }
  }

  return false
}

export const isValidEmailAddress = (emailAddress: string) => {
  if (emailAddress) {
    return is.email(emailAddress)
  }

  return false
}

export const contentScroll = {
  disable: () => {
    const $body = document.querySelector('body')
    if ($body) {
      $body.style.overflow = 'hidden'
    }
  },
  enable: () => {
    const $body = document.querySelector('body')
    if ($body) {
      $body.style.overflow = 'visible'
    }
  },
}

export const setPageTitle = (title?: string) => {
  if (title) {
    document.title = title
  }
}

const alphabetNoVowels = [
  'b',
  'c',
  'd',
  'f',
  'g',
  'h',
  'j',
  'k',
  'm',
  'n',
  'p',
  'q',
  'r',
  's',
  't',
  'v',
  'w',
  'x',
  'z',
]

export const getRandomLetters = (length = 1) => {
  let randomLetter = ''
  for (let i = 0; i < length; i++) {
    randomLetter += alphabetNoVowels[Math.floor(Math.random() * alphabetNoVowels.length)]
  }
  return randomLetter
}

export const stripHtml = (html = '') => {
  const strippedHtml = html.replace(/(<([^>]+)>)/gi, '')
  return strippedHtml
}

export const getReadableContentType = (contentType: string | undefined) => {
  return (contentType || '')
    .replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ')
    .replace('hw ', '')
    .replace('P I', 'PI')
    .replace('C D C', 'CDC')
    .replace('F D B', 'FDB')
    .replace('P D F', 'PDF')
}

export function getReducer<Type>() {
  return (state: ReducerStateWithoutAction<any>, action: Type): Type => {
    const newState = Object.assign({}, state, action)
    return newState
  }
}

export function isInIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

/**
 * Check to see if an assignment has duplicate hwids
 * @date 4/19/2023 - 9:08:42 AM
 *
 * @param {__UserAssignment} assignment
 * @returns {string[]}
 */
export const getDuplicateAssignmentHwids = (assignment?: __UserAssignment): string[] => {
  const duplicateHwids: string[] = []
  if (assignment?.content) {
    const content = assignment.content
    const dataMap: { [key: string]: number } = {}
    if (content && content.length) {
      content.forEach(contentItem => {
        const hwid = contentItem.hwid
        if (!dataMap[hwid]) {
          dataMap[hwid] = 0
        }
        dataMap[hwid] += 1
      })
      Object.keys(dataMap).forEach(hwid => {
        if (dataMap[hwid] > 1) {
          duplicateHwids.push(hwid)
        }
      })
    }
  }
  return duplicateHwids
}

export const getEstimatedTimeMinutes = (contentItem: __UserContent) => {
  if (!contentItem?.contentType) {
    return 0
  }

  if (
    contentItem.contentType === 'Video' &&
    contentItem?.videoDurationSeconds &&
    contentItem.videoDurationSeconds > 0
  ) {
    return Math.ceil(contentItem.videoDurationSeconds / 60)
  }

  if (contentItem?.estimatedTimeToReadSeconds && contentItem.estimatedTimeToReadSeconds > 0) {
    return Math.round(contentItem.estimatedTimeToReadSeconds / 60)
  }

  return 0
}
