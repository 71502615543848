import { debugErrorLogger } from 'common'
import { keyedRoutes } from './config'
import { testFeatureActive } from './features'
import { getLocalStorage } from '../__mocks__/storageFactory'

const accessTokenKey = 'access_token'

/**
 * Converts the exp time in a JWT to a fraction of a day
 *
 * @param {string} token
 * @returns {number}
 */ /* 
const oneDayInMs = 8.64e7
function getTokenExpTimeDays(token: string) {
  const parsedToken = parseToken(token)
  if (parsedToken?.exp) {
    const expDate = new Date(0)
    expDate.setUTCSeconds(parsedToken.exp)
    return (expDate.getTime() - new Date().getTime()) / oneDayInMs
  }

  return 0
} //*/

/**
 * Attempts to retrieve access token from storage
 *
 * @returns application access token
 */
export const getAppAccessToken = () => {
  return getLocalStorage().getItem(accessTokenKey)
}
/**
 * Clears the access token from storage
 */
export const clearAppAccessToken = () => {
  getLocalStorage().removeItem(accessTokenKey)
}
/**
 * Sets the access token from storage
 * @param token (JWT)
 */
export const setAppAccessToken = (token: string) => {
  if (token) {
    getLocalStorage().setItem(accessTokenKey, token)
  }
}

/**
 * Clears all access tokens from storage
 */
export const clearAllAccessTokens = () => {
  getLocalStorage().removeItem(accessTokenKey)
}

export function parseToken(jwt = getAppAccessToken()): __TokenSettings {
  if (typeof window === 'undefined') return {}
  if (jwt) {
    try {
      const base64Url = jwt.split('.')[1]
      const base64 = base64Url?.replace('-', '+').replace('_', '/')
      const parsed = JSON.parse(window.atob(base64))
      parsed.type = (parsed.iss || '').includes(':epic:') ? 'epic' : 'custom'
      return parsed
    } catch (err) {
      debugErrorLogger(`Error parsing token: ${err}`)
    }
  }
  return {}
}

const redirectItemKey = 'page_redirect'
export const setRedirectItem = () => {
  const hash = window.location.hash || ''
  keyedRoutes.forEach(routeName => {
    if (hash.startsWith(`#/${routeName}`) || hash === '#/') {
      global.localStorage.setItem(redirectItemKey, hash)
    }
  })
}

export const getRedirectItem = () => global.localStorage.getItem(redirectItemKey) || ''
export const clearRedirectItem = () => {
  global.localStorage.removeItem(redirectItemKey)
}

export const getTokenExpirationInfo = (jwt = getAppAccessToken()): __TokenInfo => {
  try {
    const parsed = parseToken(jwt)
    const currentTime = Math.floor(Date.now() / 1000)
    const expired = !parsed || !parsed.exp || currentTime > parsed.exp
    let aboutToExpire = !parsed || !parsed.exp || currentTime + 90 > parsed.exp
    if (testFeatureActive('reduceTimeToTimeoutWarning')) {
      aboutToExpire = true
    }
    const secondsRemaining =
      (parsed?.exp && parsed.exp >= 0 ? parsed.exp : currentTime) - currentTime

    return { expired, aboutToExpire, expiresAt: parsed?.exp, secondsRemaining }
  } catch (err) {}
  return {}
}

const redirectUrlKey = 'redirect_url'
const defaultRedirectUrl = undefined
export const getRedirectUrl = (): string | undefined => {
  if (typeof window !== 'undefined') {
    return window.sessionStorage.getItem(redirectUrlKey) || defaultRedirectUrl
  }
  return defaultRedirectUrl
}

export const setRedirectUrl = (token: string) => {
  if (typeof window !== 'undefined' && token) {
    window.sessionStorage.setItem(redirectUrlKey, token)
  }
}

export const clearRedirectUrl = () => {
  typeof window !== 'undefined' && window.sessionStorage.removeItem(redirectUrlKey)
}
