import { getEnvironment } from 'config'
import Cookies from 'js-cookie'

interface StorageType {
  clear(): void
  getItem(key: string): string | null
  key(index: number): string | null
  removeItem(key: string): void
  setItem(key: string, value: string): void
}

class MockStorage implements StorageType {
  storage: { [key: string]: string } = {}
  setItem = (key: string, value: string) => {
    if (!key) {
      throw new Error('Mock storage error: setItem key missing')
    }
    if (!value) {
      throw new Error('Mock storage error: setItem value missing')
    }
    this.storage[key] = value
  }
  getItem = (key: string): string | null => {
    if (!key) {
      throw new Error('Mock storage error: getItem key missing')
    }
    if (this.storage[key]) {
      return this.storage[key]
    }
    return null
  }

  removeItem = (key: string): void => {
    if (!key) {
      throw new Error('Mock storage error: removeItem key missing')
    }
    if (this.storage[key]) {
      delete this.storage[key]
    }
  }

  clear(): void {
    this.storage = {}
  }

  key(index: number): string | null {
    const keyValue = Object.keys(this.storage)[index]
    return keyValue || null
  }
}

class CookieStorage implements StorageType {
  timeout: number | undefined = undefined
  constructor(timeout?: number) {
    if ((timeout || 0) > 0) {
      this.timeout = timeout
    }
  }
  setItem = (key: string, value: string, expires?: number) => {
    if (!key) {
      throw new Error('Cookie storage error: setItem key missing')
    }
    if (!value && value !== '') {
      throw new Error('Cookie storage error: setItem value missing')
    }
    const env = getEnvironment()
    const secureSettings = { sameSite: 'strict', secure: true }
    if (expires && expires > 0) {
      const expSettings = { expires }
      const cookieSettings = env.dev ? expSettings : Object.assign({}, expSettings, secureSettings)
      Cookies.set(key, value, cookieSettings)
    } else if (this.timeout && this.timeout > 0) {
      const expSettings = { expires: this.timeout }
      const cookieSettings = env.dev ? expSettings : Object.assign({}, expSettings, secureSettings)
      Cookies.set(key, value, cookieSettings)
    } else {
      const cookieSettings = env.dev ? {} : secureSettings
      Cookies.set(key, value, cookieSettings)
    }
  }

  getItem = (key: string): string | null => {
    if (!key) {
      throw new Error('Cookie storage error: getItem key missing')
    }
    if (Cookies.get(key)) {
      return Cookies.get(key) || null
    }
    return null
  }

  removeItem = (key: string): void => {
    if (!key) {
      throw new Error('Cookie storage error: removeItem key missing')
    }
    if (Cookies.get(key)) {
      Cookies.remove(key)
    }
  }

  clear(): void {
    throw new Error('Clear method not implemented in CookieStorage.')
  }

  key(index = 0): string | null {
    throw new Error('Key method not implemented in CookieStorage. ' + index)
  }
}

/**
 * You may look at the branching logic for local storage and think
 *  to yourself, "Wow! This is stupid!" and you'd be right.
 * (just not for the reason you think at first - the localStorage is only
 * writing to sessionStorage when it's passed around - yeah, I don't know)
 **/

class StorageFactory<StringType> {
  uniqueIdentifier = 'defaultDataKey'
  storageMethod: Storage | StorageType

  constructor(uniqueIdentifier: string, storageMethod?: Storage | StorageType) {
    if (!uniqueIdentifier) {
      throw new Error('Storage factory error: dataKey argument is invalid')
    }
    this.uniqueIdentifier = uniqueIdentifier

    if (storageMethod) {
      this.storageMethod = storageMethod
    } else {
      this.storageMethod = new MockStorage()
    }
  }

  public set(settingName: StringType, setting?: string): void {
    if (settingName) {
      if (this.storageMethod === global.localStorage) {
        const normalizedSetting = JSON.parse(
          global.localStorage.getItem(this.uniqueIdentifier) || '{}',
        )
        normalizedSetting[settingName] = setting
        global.localStorage.setItem(this.uniqueIdentifier, JSON.stringify(normalizedSetting))
      } else {
        const normalizedSetting = JSON.parse(
          this.storageMethod.getItem(this.uniqueIdentifier) || '{}',
        )
        normalizedSetting[settingName] = setting
        this.storageMethod.setItem(this.uniqueIdentifier, JSON.stringify(normalizedSetting))
      }
    }
  }

  public get(settingName: StringType, fallback?: string | null): string | null {
    if (settingName) {
      if (this.storageMethod === global.localStorage) {
        const normalizedSetting = JSON.parse(
          global.localStorage.getItem(this.uniqueIdentifier) || '{}',
        )
        return normalizedSetting[settingName]
      }
      const normalizedSetting = JSON.parse(
        this.storageMethod.getItem(this.uniqueIdentifier) || '{}',
      )
      return normalizedSetting[settingName]
    }
    return fallback || null
  }

  public clear(settingName: StringType): void {
    if (this.storageMethod === global.localStorage) {
      const normalizedSettings = JSON.parse(
        global.localStorage.getItem(this.uniqueIdentifier) || '{}',
      )
      if (normalizedSettings[settingName]) {
        delete normalizedSettings[settingName]
      }
      global.localStorage.setItem(this.uniqueIdentifier, JSON.stringify(normalizedSettings))
    } else {
      const normalizedSettings = JSON.parse(
        this.storageMethod.getItem(this.uniqueIdentifier) || '{}',
      )
      if (normalizedSettings[settingName]) {
        delete normalizedSettings[settingName]
      }
      this.storageMethod.setItem(this.uniqueIdentifier, JSON.stringify(normalizedSettings))
    }
  }

  public purge = (): void => {
    this.storageMethod === global?.localStorage
      ? global.localStorage.removeItem(this.uniqueIdentifier)
      : this.storageMethod.removeItem(this.uniqueIdentifier)
  }
}

const mockStorageInstance = new MockStorage()

function getLocalStorage(storageToUse?: Storage | MockStorage) {
  let storageHandler: Storage | MockStorage = mockStorageInstance
  if (storageToUse) {
    storageHandler = storageToUse
  } else {
    try {
      if (typeof window !== 'undefined' && window?.localStorage) {
        storageHandler = window.localStorage
      } else if (typeof global !== 'undefined' && global?.localStorage) {
        storageHandler = global.localStorage
      }
    } catch (err) {
      storageHandler = mockStorageInstance
    }
  }

  return storageHandler
}

function getSessionStorage(storageToUse?: Storage | MockStorage) {
  let storageHandler: Storage | MockStorage = mockStorageInstance
  if (storageToUse) {
    storageHandler = storageToUse
  } else {
    try {
      if (typeof window !== 'undefined' && window?.sessionStorage) {
        storageHandler = window.sessionStorage
      } else if (typeof global !== 'undefined' && global?.sessionStorage) {
        storageHandler = global.sessionStorage
      }
    } catch (err) {
      storageHandler = mockStorageInstance
    }
  }
  return storageHandler
}

export { MockStorage, CookieStorage, mockStorageInstance, getLocalStorage, getSessionStorage }

export type { StorageType }
export default StorageFactory
