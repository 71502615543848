import { useState, useEffect, useMemo, useCallback } from 'react'
import algoliasearch, { SearchClient } from 'algoliasearch/lite'
import { defaultLocalization, displayLocalizations, latestDisplayLocalizations } from 'config'
import { FilterHandlerType, FilterPropertiesType, attributeLabels } from 'filters/filter.config'
import FilterManager from 'filters/filter.manager'
import { SearchIndex } from 'algoliasearch'
import { debugErrorLogger, verifyLang } from 'common'
import { customChangeLocEventName } from 'localize'
import { testFeatureActive } from 'features'
import { getUxLang } from 'localize/localizeTypes'
import customFetch from 'apis/customFetch'
import StorageFactory, { getSessionStorage } from 'storageFactory'
import { useAppContext } from 'context/useAppContext'
import { faro, LogLevel } from '@grafana/faro-web-sdk'

export type AlgoliaKeyDataAudience = 'consumer' | 'advise' | undefined
interface Props {
  algoliaSettings: __SearchContextData
  filterLabel: string
  audience: AlgoliaKeyDataAudience
}

type AlgoliaKeyDataFacet = { attributes: string[]; values: string[]; joiner: string }
type AlgoliaKeyDataResult = {
  initialFilterString: string
  facets: AlgoliaKeyDataFacet[]
}

type AlgoliaKeyDataIndexes = {
  default: {
    suggestionsIndex: string
    wordsIndex: string
  }
}

export interface AlgoliaKeyData {
  queryKey: string
  queryAppId: string
  audience?: AlgoliaKeyDataAudience
  results: AlgoliaKeyDataResult[]
  indexes: AlgoliaKeyDataIndexes
}

export type SecurityCredentialsType = {
  orgId: string
  subId: string
}

export interface SearchCommonType {
  lookupReady: boolean
  lookupError: Error | undefined
  searchClient: SearchClient | null
  wordsIndex: string
  suggestionsIndex: string
  searchQueryKey: string
  initialFilterString: string
  filterProperties: FilterPropertiesType
  filterHandler?: FilterHandlerType
  searchIndex?: SearchIndex
  securityCredentials?: SecurityCredentialsType
  clearLookupStoredState?: () => void
}

export const emptyAlgoliaKeyData: AlgoliaKeyData = {
  queryAppId: '',
  queryKey: '',
  audience: 'advise',
  indexes: {
    default: {
      suggestionsIndex: '',
      wordsIndex: '',
    },
  },
  results: [],
}

const lookupSettingsKey = 'lookup_settings'
const lookupSettings = new StorageFactory<string>(lookupSettingsKey, getSessionStorage())

const useLookupContextCommon: (props: Props) => SearchCommonType = ({
  filterLabel,
  audience = '',
}) => {
  const { orgId, subId, authToken, verifyToken } = useAppContext()
  const [wordsIndex, _setWordsIndex] = useState<string>('')
  const [searchQueryKey, _setSearchQueryKey] = useState<string>('')
  const [suggestionsIndex, _setSuggestionsIndex] = useState<string>('')
  const [initialFilterString, _setInitialFilterString] = useState<string>('')
  const [lookupError, setLookupError] = useState<Error | undefined>(undefined)
  const [lookupReady, setLookupReady] = useState<boolean>(false)
  const [searchIndex, setSearchIndex] = useState<SearchIndex | undefined>()
  const { filterProperties, filterHandler } = FilterManager(filterLabel)
  const [searchClient, setSearchClient] = useState<SearchClient | null>(null)

  const isAuthenticated = useMemo(() => {
    return Boolean(authToken && verifyToken?.())
  }, [authToken])

  const setWordsIndex = (newWordsIndex: string) => {
    lookupSettings.set('wordsIndex-' + filterLabel, newWordsIndex)
    _setWordsIndex(newWordsIndex)
  }

  const setSearchQueryKey = (newQueryKey: string) => {
    lookupSettings.set('searchQueryKey-' + filterLabel, newQueryKey)
    _setSearchQueryKey(newQueryKey)
  }

  const setSuggestionsIndex = (newSuggestionsIndex: string) => {
    lookupSettings.set('suggestionsIndex-' + filterLabel, newSuggestionsIndex)
    _setSuggestionsIndex(newSuggestionsIndex)
  }

  const setInitialFilterString = (newFilterString: string) => {
    lookupSettings.set('initialFilterString-' + filterLabel, newFilterString)
    _setInitialFilterString(newFilterString)
  }

  const showMultiLanguageSwitcher = testFeatureActive('showMultiLanguageSwitcher')
  const uiLocalizations: __Localization[] = useMemo(() => {
    if (showMultiLanguageSwitcher) {
      return latestDisplayLocalizations
    }
    return displayLocalizations
  }, [showMultiLanguageSwitcher])

  const changeAlgoliaSettings = (
    queryKey: string,
    indexes: { wordsIndex: string; suggestionsIndex: string },
  ) => {
    if (queryKey) {
      setSearchQueryKey(queryKey)
    }
    if (indexes?.wordsIndex && indexes.suggestionsIndex) {
      setWordsIndex(indexes.wordsIndex)
      setSuggestionsIndex(indexes.suggestionsIndex)
    }
  }

  useEffect(() => {
    if (wordsIndex && !searchIndex && searchClient) {
      const newSearchIndex: SearchIndex = searchClient.initIndex(wordsIndex) as SearchIndex
      setSearchIndex(newSearchIndex)
    }
  }, [wordsIndex, searchIndex, searchClient])

  useEffect(() => {
    const changeAlgoliaLanguageQuery = (loc: __Localization) => {
      if (verifyLang(loc) && uiLocalizations.includes(loc)) {
        filterHandler.remove(attributeLabels.displayLanguage)
        filterHandler.add(attributeLabels.displayLanguage, loc)
      }
    }

    const handleLanguageChange = (e: any) => {
      const loc: __Localization = e?.detail?.localization
      if (loc) {
        changeAlgoliaLanguageQuery(loc)
      }
    }

    window.addEventListener(customChangeLocEventName, handleLanguageChange)

    return () => {
      window.removeEventListener(customChangeLocEventName, handleLanguageChange)
    }
  }, [filterHandler, uiLocalizations])

  const getSearchSettings = async (): Promise<AlgoliaKeyData> => {
    if (isAuthenticated && orgId && subId) {
      setLookupError(undefined)
      const response = await customFetch(`auth/algoliakeydatalist/${orgId}/${subId}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      if (response?.ok) {
        const keyData = (await response.json()) as AlgoliaKeyData[]
        if (keyData?.length) {
          const result = keyData.find(datum => datum.audience === audience)
          if (result?.queryKey) {
            return result as AlgoliaKeyData
          }
        }
        const error = new Error(
          `Failed to get settings token, could not find audience: ${audience}`,
        )
        setLookupError(error)
        debugErrorLogger(error.message)
        faro?.api?.pushLog([error.message], { level: LogLevel.ERROR })
      } else {
        const error = new Error(`Failed to get settings token, Status: ${response?.status}`)
        setLookupError(error)
        console.error(error.message)
        faro?.api?.pushLog([error.message], { level: LogLevel.ERROR })
      }
    }
    return emptyAlgoliaKeyData
  }

  const updateLookupSettingsList = useCallback(async () => {
    let storedUxLang = getUxLang() as __Localization
    if (!verifyLang(storedUxLang)) {
      storedUxLang = defaultLocalization
    }
    const searchSettings = await getSearchSettings()

    const queryKey = searchSettings?.queryKey
    const appId = searchSettings?.queryAppId

    if (queryKey && searchSettings?.indexes?.default) {
      changeAlgoliaSettings(queryKey, searchSettings?.indexes?.default)
      setSearchClient(algoliasearch(appId, queryKey))
      const initFilterString =
        searchSettings?.results?.[0]?.initialFilterString ||
        `(${attributeLabels.displayLanguage}:'${defaultLocalization}')`
      filterHandler.initialize(initFilterString)
      if (storedUxLang && uiLocalizations.includes(storedUxLang)) {
        filterHandler.remove(attributeLabels.displayLanguage)
        filterHandler.add(attributeLabels.displayLanguage, storedUxLang)
      }
      setInitialFilterString(filterProperties.aisFilterString)
      setLookupError(undefined)
      setLookupReady(true)
    }
  }, [filterHandler, uiLocalizations, audience])

  const resurrectState = () => {
    const storedWordsIndex = lookupSettings.get('wordsIndex-' + filterLabel)
    if (storedWordsIndex) _setWordsIndex(storedWordsIndex)
    const storedQueryKey = lookupSettings.get('searchQueryKey-' + filterLabel)
    if (storedQueryKey) _setSearchQueryKey(storedQueryKey)
    const storedSuggestionsIndex = lookupSettings.get('suggestionsIndex-' + filterLabel)
    if (storedSuggestionsIndex) _setSuggestionsIndex(storedSuggestionsIndex)
    const storedInitialFilterString = lookupSettings.get('initialFilterString-' + filterLabel)
    if (storedInitialFilterString) {
      _setInitialFilterString(storedInitialFilterString)
      if (filterProperties.aisFilterString !== storedInitialFilterString) {
        filterHandler.initialize(storedInitialFilterString)
      }
    }
  }

  const clearLookupStoredState = () => {
    lookupSettings.clear('wordsIndex-' + filterLabel)
    lookupSettings.clear('searchQueryKey-' + filterLabel)
    lookupSettings.clear('suggestionsIndex-' + filterLabel)
    lookupSettings.clear('initialFilterString-' + filterLabel)
  }

  // ********** Initialize
  useEffect(() => {
    resurrectState()
    updateLookupSettingsList()
  }, [])

  useEffect(() => {
    if (orgId && subId && isAuthenticated && !lookupReady) {
      updateLookupSettingsList()
    }
  }, [orgId, subId, lookupReady, isAuthenticated])

  useEffect(() => {
    setLookupReady(
      Boolean(
        orgId && subId && wordsIndex && suggestionsIndex && searchQueryKey && isAuthenticated,
      ),
    )
  }, [orgId, subId, wordsIndex, suggestionsIndex, searchQueryKey, isAuthenticated])

  return {
    lookupReady,
    lookupError,
    searchClient,
    wordsIndex,
    suggestionsIndex,
    initialFilterString,
    filterProperties,
    filterHandler,
    searchQueryKey,
    searchIndex,
    clearLookupStoredState,
  }
}

export default useLookupContextCommon
