import {
  AdviseLogo,
  ArrowDown,
  ArrowUp,
  Article,
  BrokenLink,
  ChevronDown,
  ChevronRight,
  ChevronLeft,
  ChevronUp,
  Checkbox,
  Checkmark,
  Clock,
  Dashboard,
  DashboardNoResultsEnd,
  Edit,
  EmailAlternate,
  EmailCode,
  EndOfSearchResults,
  EndOfResults,
  Error,
  ErrorUnknownType,
  External,
  FileSize,
  Font,
  HandshakeIcon,
  HandWave,
  HourglassTop,
  IconX,
  Info,
  Language,
  Location,
  Lock,
  Login,
  Logout,
  MissingPage,
  MobilePhone,
  NoAssignment,
  NotVerified,
  PageNumber,
  PauseCircle,
  PDF,
  PersonColor,
  Phone,
  PhoneCode,
  PLayCircle,
  Preview,
  Printer,
  RatingsIcon,
  Search,
  SearchLibraryLeft,
  SearchLibraryNoResultsEnd,
  SearchLibraryRight,
  SearchMissing,
  Settings,
  Success,
  TextSize,
  ThumbsDown,
  ThumbsDownFilled,
  ThumbsUp,
  ThumbsUpFilled,
  User,
  Video,
  VideoCaptions,
  VideoFull,
  VideoFullExit,
  VideoMute,
  VideoPause,
  VideoPlay,
  VideoSpeed,
  VideoVolume,
  View,
  Warning,
  Welcome,
} from './icons.jsx'

const AllIcons = [
  AdviseLogo,
  ArrowDown,
  ArrowUp,
  Article,
  BrokenLink,
  ChevronDown,
  ChevronRight,
  ChevronLeft,
  ChevronUp,
  Checkbox,
  Checkmark,
  Clock,
  Dashboard,
  DashboardNoResultsEnd,
  Edit,
  EmailAlternate,
  EmailCode,
  EndOfSearchResults,
  EndOfResults,
  Error,
  ErrorUnknownType,
  External,
  FileSize,
  Font,
  HandshakeIcon,
  HandWave,
  HourglassTop,
  IconX,
  Info,
  Language,
  Location,
  Lock,
  Login,
  Logout,
  MissingPage,
  MobilePhone,
  NoAssignment,
  NotVerified,
  PageNumber,
  PauseCircle,
  PDF,
  PersonColor,
  Phone,
  PhoneCode,
  PLayCircle,
  Preview,
  Printer,
  RatingsIcon,
  Search,
  SearchLibraryLeft,
  SearchLibraryNoResultsEnd,
  SearchLibraryRight,
  SearchMissing,
  Settings,
  Success,
  TextSize,
  ThumbsDown,
  ThumbsDownFilled,
  ThumbsUp,
  ThumbsUpFilled,
  User,
  Video,
  VideoCaptions,
  VideoFull,
  VideoFullExit,
  VideoMute,
  VideoPause,
  VideoPlay,
  VideoSpeed,
  VideoVolume,
  View,
  Warning,
  Welcome,
]

export {
  AllIcons,
  AdviseLogo,
  ArrowDown,
  ArrowUp,
  Article,
  BrokenLink,
  ChevronDown,
  ChevronRight,
  ChevronLeft,
  ChevronUp,
  Checkbox,
  Checkmark,
  Clock,
  Dashboard,
  DashboardNoResultsEnd,
  Edit,
  EmailAlternate,
  EmailCode,
  EndOfSearchResults,
  EndOfResults,
  Error,
  ErrorUnknownType,
  External,
  FileSize,
  Font,
  HandshakeIcon,
  HandWave,
  HourglassTop,
  IconX,
  Info,
  Language,
  Location,
  Lock,
  Login,
  Logout,
  MissingPage,
  Phone,
  MobilePhone,
  NoAssignment,
  NotVerified,
  PageNumber,
  PauseCircle,
  PDF,
  PersonColor,
  PhoneCode,
  PLayCircle,
  Preview,
  Printer,
  RatingsIcon,
  Search,
  SearchLibraryLeft,
  SearchLibraryNoResultsEnd,
  SearchLibraryRight,
  SearchMissing,
  Settings,
  Success,
  TextSize,
  ThumbsDown,
  ThumbsDownFilled,
  ThumbsUp,
  ThumbsUpFilled,
  User,
  Video,
  VideoCaptions,
  VideoFull,
  VideoFullExit,
  VideoMute,
  VideoPause,
  VideoPlay,
  VideoSpeed,
  VideoVolume,
  View,
  Warning,
  Welcome,
}
