import { createContext, useContext, useState, useEffect, FunctionComponent, useMemo } from 'react'
import localizeCommon from './localizeCommon.loc.json'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/zh-cn'
// import 'moment/locale/fr' // add additional languages as needed
import {
  defaultLocalization,
  defaultLanguageCode,
  defaultCountryCode,
  dmyDateFormatCountries,
  supportedCountryCodes,
  supportedLangCodes,
  getLangFromLoc,
} from '../config'
import {
  LocalizerType,
  LocalizeListType,
  LocalizationNameType,
  LocalizeFormType,
  initialLocalizer,
  dateFormats,
  OnChangeEventType,
  getUxLang,
  setUxLang,
  getBrowserLocalization,
} from './localizeTypes'
import { debugErrorLogger, debugLogger } from '../common'
import languageCodeJson from './languageCodeLookup.json'

const LocalizeContext = createContext<LocalizerType>(initialLocalizer)

const localCache: { localizeList: LocalizeListType } = {
  localizeList: Object.assign({}, localizeCommon),
}

export const customChangeLocEventName = 'hw-localize-change-localization'

const testLocalizeKeys = (localizeKeys: string[], verbose = false) => {
  const localizeListKeys = Object.keys(localCache.localizeList)
  localizeListKeys.forEach(localizeListKey => {
    if (localizeKeys.includes(localizeListKey)) {
      verbose &&
        console.warn &&
        console.warn(
          `LOCALIZATION WARNING: The key '${localizeListKey}' already exists and will be overwritten`,
        )
    }
  })
}

export const AttachLocalizedList = (localizeListToAttach: LocalizeListType): void => {
  const localizeKeys = Object.keys(localizeListToAttach)
  if (typeof localizeListToAttach === 'object' && localizeKeys.length > 0) {
    testLocalizeKeys(localizeKeys)
    localCache.localizeList = Object.assign(localCache.localizeList, localizeListToAttach)
  }
}

export const LocalizeForm: LocalizeFormType = {
  future: 'future',
  past: 'past',
  plural: 'plural',
  present: 'present',
  singular: 'singular',
  feminine: 'feminine',
  masculine: 'masculine',
}

// Using array so that events fire in the order they were added
const onChangeEvents: OnChangeEventType[] = []

export const getOnChangeEvents = () => {
  return [...onChangeEvents]
}

interface Props {
  children?: React.ReactElement | React.ReactElement[] | undefined
  initialLocalization?: __Localization | string | undefined
}

const LocalizeWrapper: FunctionComponent<Props> = ({ children, initialLocalization }) => {
  const [localization, setLocalization] = useState<string | __Localization>(
    initialLocalization || defaultLocalization,
  )
  const [verbose, setVerbose] = useState<boolean>(false)
  const languageCode = useMemo(() => {
    return getLangFromLoc(localization)
  }, [localization])

  const langTextRegex = /[a-z]{2}|[a-z]{2}-[a-z]{2}$/

  const verifyLang = (lang: string): boolean => {
    const testLang = (lang || '').toLowerCase().replace(/ /g, '')
    if (testLang.match(langTextRegex)) {
      if (testLang.length === 2) {
        return supportedLangCodes.includes(testLang as __Language)
      }
      if (testLang.length === 5 && testLang.indexOf('-') === 2) {
        const langParts = testLang.split('-')
        return (
          langParts.length === 2 &&
          supportedLangCodes.includes(langParts[0] as __Language) &&
          supportedCountryCodes.includes(langParts[1])
        )
      }
    }

    return false
  }

  const sendLocalizationChangeEvent = (loc: __Localization) => {
    window.dispatchEvent(
      new CustomEvent(customChangeLocEventName, {
        bubbles: true,
        detail: { localization: loc },
      }),
    )
  }

  const changeLocalization = (lang: string): void => {
    const testLang = (lang || '').toLowerCase()
    if (!lang) {
      setLocalization('')
      setUxLang('')
    } else if (verifyLang(testLang)) {
      setLocalization(testLang)
      setUxLang(testLang)
      sendLocalizationChangeEvent(testLang as __Localization)
      if (onChangeEvents.length > 0) {
        onChangeEvents.forEach(changeEvent => {
          try {
            changeEvent.event(testLang as __Localization, changeEvent.name)
            debugLogger('Change event firing', changeEvent.name)
          } catch (err: any) {
            console.error('Could not fire localization change event:', changeEvent.name, err)
          }
        })
      }
    } else {
      console.error('Invalid language', lang)
    }
  }

  // ********** Initialize
  useEffect(() => {
    const storedLocalization = getUxLang(verbose)
    if (storedLocalization && storedLocalization !== localization) {
      changeLocalization(storedLocalization)
      sendLocalizationChangeEvent(storedLocalization as __Localization)
    }
  }, [])

  // Based off of
  // https://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format#answer-13439711
  const format = (str: string, args: (string | LocalizeFormType)[]) => {
    let fIndex = -1
    const callback = (
      exp: string | number | undefined,
      p0: string,
      p1: string,
      p2: string,
      p3: string,
      p4: string,
    ) => {
      if (exp === '%%') return '%'
      if (args[++fIndex] === undefined) return undefined
      exp = p2 ? parseInt(p2.substring(1)) : undefined
      let base = p3 ? parseInt(p3.substring(1)) : undefined
      let val: any = ''
      switch (p4) {
        case 's':
        case 'o':
          val = args[fIndex]
          break
        case 'f':
          if (exp) {
            val = parseFloat(args[fIndex] as string).toFixed(exp)
          } else {
            val = parseFloat(args[fIndex] as string)
          }
          break
        case 'p':
          val = parseFloat(args[fIndex] as string).toPrecision(exp)
          break
        case 'i':
          val = parseInt(args[fIndex] as string).toString(base ? base : 10)
          break
        default:
          break
      }
      if (typeof val === 'object') {
        if (p4 === 'o') {
          if (val.children) {
            let optionalAttributes = ''
            for (let key in val) {
              const value = val[key]
              if (['children', 'href'].includes(key)) {
                continue
              } else if (key === 'className') {
                optionalAttributes += ` class="${value}"`
              } else {
                optionalAttributes += ` ${key}="${value}"`
              }
            }
            if (val.href) {
              val = `<a href="${val.href}"${optionalAttributes}>${val.children}</a>`
            } else {
              val = `<span${optionalAttributes}>${val.children}</span>`
            }
          } else {
            val = JSON.stringify(val)
            console.error(`Link replacements require children argument ${val}`)
          }
        } else {
          val = JSON.stringify(val)
        }
      }

      let paddingSize = parseInt(p1)
      let ch = p1 && p1[0] === '0' ? '0' : ' '
      while (val.length < paddingSize) {
        val = p0 !== undefined ? val + ch : ch + val
      }
      return val
    }
    const regex = /%(-)?(0?[0-9]+)?([.][0-9]+)?([#][0-9]+)?([sfpoi%])/g
    return str.replace(regex, callback)
  }

  const Localize = (id: string, ...settings: any[]): string => {
    const form: LocalizeFormType = settings?.[0]
    const args = (settings || ['']).splice(1)
    const backupLang = getLangFromLoc(getBrowserLocalization())
    const lang = localization || typeof getUxLang === 'function' ? getUxLang() : defaultLocalization
    if (id && typeof lang === 'string' && lang?.length >= 2) {
      let lowerLang: string = lang.toLocaleLowerCase()
      let localizeForm: string = LocalizeForm.singular
      let replacements: LocalizeFormType[] = []
      const stringForm = `${form}`

      /**
       * %s string
       * %i int
       * %f[.p] float
       */

      // shorthand: if form === true then use plural
      if (typeof form === 'boolean' && form) {
        localizeForm = LocalizeForm.plural
      } else if (typeof form === 'string' && LocalizeForm[form]) {
        localizeForm = form
      } else if (
        typeof form === 'string' &&
        stringForm.startsWith('lang:') &&
        verifyLang(stringForm.substring(5))
      ) {
        lowerLang = stringForm.substring(5).toLocaleLowerCase()
      } else if (form) {
        replacements.push(form)
      }
      replacements = replacements.concat(args)

      const loc = getLangFromLoc(lowerLang)
      let localizeListItem: any = ''
      let translationMissing = false
      if (localCache.localizeList[id]) {
        if (localCache.localizeList[id][lowerLang]) {
          localizeListItem = localCache.localizeList[id][lowerLang]
        } else if (localCache.localizeList[id][loc]) {
          localizeListItem = localCache.localizeList[id][loc]
        } else if (localCache.localizeList[id][backupLang]) {
          localizeListItem = localCache.localizeList[id][backupLang]
          translationMissing = true
        }
      }

      const translationNeededLabel = translationMissing
        ? ` [${loc ? loc.toUpperCase() : 'NT'}]`
        : ''

      if (localizeListItem) {
        if (localizeListItem[localizeForm]) {
          if (replacements.length && localizeListItem[localizeForm].includes('%')) {
            return format(localizeListItem[localizeForm], replacements) + translationNeededLabel
          }
          return localizeListItem[localizeForm] + translationNeededLabel
        }
        if (replacements.length && localizeListItem.includes('%')) {
          return format(localizeListItem, replacements) + translationNeededLabel
        }
        return localizeListItem + translationNeededLabel
      }
      verbose && debugErrorLogger(`**[MISSING LOCALIZATION|${id}|${lang}]**`)
      return `**[${id}|${lang}]**`
    }
    verbose && debugErrorLogger(`**[INVALID LOCALIZE|${id}|${lang}]**`)
    return `**[INVALID LOCALIZE|${id}|${lang}]**`
  }

  const getAge = (
    birthDate: Date | string | number | undefined,
    dateToCheck: Date | string | number = new Date(),
  ) => {
    const normalizedEndDate = new Date(dateToCheck)
    const normalizedDate = new Date(birthDate || '')
    if (normalizedDate) {
      const timeDifference =
        (normalizedEndDate?.getTime() || new Date().getTime()) - normalizedDate.getTime()
      const ageInDays = Math.floor(timeDifference / (1000 * 3600 * 24))
      const ageInMonths = Math.floor(timeDifference / (1000 * 3600 * 24 * 30.417))
      const ageInYears = Math.floor(timeDifference / (1000 * 3600 * 24 * 365))
      if (ageInDays >= 0) {
        if (ageInDays < 31) {
          return `${ageInDays} ${Localize('Day', ageInDays !== 1)}`
        } else if (ageInMonths <= 23) {
          return `${ageInMonths} ${Localize('Month', ageInMonths !== 1)}`
        }
        return `${ageInYears} ${Localize('Year', ageInYears !== 1)}`
      }
    }
    return Localize('InvalidAge')
  }

  const LocalizeDate = (
    date?: Date | string | undefined,
    format: dateFormats = dateFormats.standard,
  ): string => {
    if (date && localization) {
      const languageCode =
        localization.length === 5 ? getLangFromLoc(localization) : defaultLanguageCode
      moment.locale(languageCode)

      const countryCode = localization.length === 5 ? localization.substring(3) : defaultCountryCode
      const dateCandidate = date instanceof Date ? date : new Date(date)
      const mDate = moment(dateCandidate)

      if (dmyDateFormatCountries.includes(countryCode)) {
        switch (format) {
          case dateFormats.medicalLong:
            return mDate.format('DD MMM YYYY h:mm a')
          case dateFormats.medical:
            return mDate.format('DD MMM YYYY')
          default:
            return mDate.format('DD/MM/YYYY')
        }
      }
      switch (format) {
        case dateFormats.medicalLong:
          return mDate.format('DD MMM YYYY h:mm a')
        case dateFormats.medical:
          return mDate.format('DD MMM YYYY')
        default:
          return mDate.format('MM/DD/YYYY')
      }
    }
    return date ? date.toString() : ''
  }

  const getLocalizationName = (lang: string): LocalizationNameType | undefined => {
    const lookupValue = languageCodeJson.find((localizationName: LocalizationNameType) => {
      return localizationName.localization === lang
    })
    return lookupValue
  }

  const localizeState = {
    localization,
    languageCode,
    setVerbose,
    verbose,
    getAge,
    changeLocalization,
    LocalizeDate,
    Localize,
    getLocalizationName,
    verifyLang,
  }

  return <LocalizeContext.Provider value={localizeState}>{children}</LocalizeContext.Provider>
}

export function useLocalize() {
  return useContext(LocalizeContext)
}

export default LocalizeWrapper
