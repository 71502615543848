import { createContext, useContext, useState, useEffect, FunctionComponent, useMemo } from 'react'
import {
  defaultCountryCode,
  defaultLanguageCode,
  displayLocalizations,
  defaultLocalization,
  latestDisplayLocalizations,
  theUnPatientId,
  noFirstNamePlaceholder,
} from 'config'
import { IconX } from 'components/Icons'
import { useLocalize } from 'localize'
import styles from './AppContext.module.css'
import StorageFactory, { getLocalStorage, getSessionStorage } from 'storageFactory'
import {
  getAppAccessToken,
  setAppAccessToken,
  parseToken,
  getTokenExpirationInfo,
  clearAppAccessToken,
} from 'token-utils'
import { debugErrorLogger, debugLogger, verifyGuid } from 'common'
import { initializeFeatures, testFeatureActive } from 'features'
import classNames from 'classnames'
import { faro, LogLevel } from '@grafana/faro-web-sdk'

const defaultLoginInfo: __CommunicationOptions = {
  patientId: '',
  hasEmail: false,
  hasPhoneNumber: false,
  maskedEmail: '',
  maskedPhoneNumber: '',
}

const defaultState: __GlobalAppContext = {
  authToken: '',
  currentUser: {},
  loginInfo: defaultLoginInfo,
}

const defaultUserAppSettings: __UserAppSettings = {
  disableAnimation: false,
  preferredContentLoc: defaultLocalization,
  textSize: 'normal',
}

const AppContext = createContext<__GlobalAppContext>(defaultState)

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

const appSettingsKey = 'app_settings'
type appSettingKeys = 'user' | 'orgId' | 'subId' | 'clientId'
const appSettings = new StorageFactory<appSettingKeys>(appSettingsKey, getLocalStorage())

const tempSettingsKey = 'temp_settings'
type tempSettingKeys =
  | 'userSettings'
  | 'isEmbedded'
  | 'loginRedirect'
  | 'skipRedirect'
  | 'skipLogin'
  | 'lastGoodUrl'
  | 'communicationDown'
  | 'assignmentId'
  | 'assignmentArtifact'
const tempSettings = new StorageFactory<tempSettingKeys>(tempSettingsKey, getSessionStorage())

const AppWrapper: FunctionComponent<Props> = ({ children }) => {
  const { Localize } = useLocalize()
  const [shouldReset, setShouldReset] = useState<boolean>(false)

  const [authToken, _setAuthToken] = useState<string | null>('')
  const [orgId, _setOrgId] = useState<string>('')
  const [subId, _setSubId] = useState<string>('')
  const [currentUser, _setCurrentUser] = useState<__User>()
  const [userAppSettings, setUserAppSettings] = useState<__UserAppSettings>(defaultUserAppSettings)

  const [loginRedirect, _setLoginRedirect] = useState<string>('')
  const [skipRedirect, _setSkipRedirect] = useState<string>('')
  const [lastGoodUrl, _setLastGoodUrl] = useState<string>('')

  const [savedLoginInfo, setSavedLoginInfo] = useState<__CommunicationOptions>(
    Object.assign({}, defaultLoginInfo),
  )

  const [isEmbedded, _setIsEmbedded] = useState<boolean>(false)
  const [skipLogin, _setSkipLogin] = useState<boolean>(false)
  const [communicationDown, _setCommunicationDown] = useState<boolean>(false)

  const [timeoutDateTime, setTimeoutDateTime] = useState<Date | undefined>(undefined)

  const [errors, setErrors] = useState<string[]>([])
  const [warnings, setWarnings] = useState<string[]>([])

  const [assignmentId, _setAssignmentId] = useState<string>('')
  const [assignmentArtifact, _setAssignmentArtifact] = useState<string>('')

  const storedToken = useMemo(() => {
    return getAppAccessToken()
  }, [authToken])

  const showMultiLanguageSwitcher = testFeatureActive('showMultiLanguageSwitcher')
  const uiLocalizations: __Localization[] = useMemo(() => {
    if (showMultiLanguageSwitcher) {
      return latestDisplayLocalizations
    }
    return displayLocalizations
  }, [showMultiLanguageSwitcher])

  const setCurrentUser = (newUser: __User) => {
    if (newUser) {
      appSettings.set('user', JSON.stringify(newUser))
      _setCurrentUser(newUser)
    }
  }

  const updateTimeoutDateTime = (localAuthToken: string | null) => {
    const tokenToUse = localAuthToken || authToken
    const parsedToken = parseToken(tokenToUse)
    const tokenInfo = getTokenExpirationInfo(tokenToUse)
    if (parsedToken.exp && !tokenInfo.expired) {
      const expDate = new Date(0)
      expDate.setUTCSeconds(parsedToken.exp)
      if (!timeoutDateTime || expDate.getTime() !== timeoutDateTime?.getTime()) {
        setTimeoutDateTime(expDate)
      }
    }
  }

  const setUserFromToken = (localAuthToken?: string) => {
    const parsedToken = parseToken(localAuthToken || authToken)
    if (parsedToken?.sub) {
      const locToUse: __Localization = (parsedToken.preferredLanguage ||
        defaultLanguageCode) as __Localization
      const isUxLanguage = uiLocalizations.includes(locToUse)
      const uxLanguage = isUxLanguage ? locToUse : defaultLocalization
      const firstName = parsedToken.firstName ? parsedToken.firstName : noFirstNamePlaceholder
      const lastName = firstName === noFirstNamePlaceholder ? '' : parsedToken.lastName || ''
      const user: __User = {
        id: parsedToken.sub,
        firstName,
        lastName,
        localizations: { preferred: locToUse, ux: uxLanguage, content: locToUse },
        country: parsedToken.country || defaultCountryCode,
        type: parsedToken.type,
      }
      setCurrentUser(user)
      updateTimeoutDateTime(localAuthToken || authToken)
    }
  }

  const setAuthToken = (
    localAuthToken: string | null,
    shouldSetUser: boolean | undefined = false,
  ) => {
    if (localAuthToken || localAuthToken === '') {
      clearAppAccessToken()
      _setAuthToken(localAuthToken)
      updateTimeoutDateTime(localAuthToken)
      setAppAccessToken(localAuthToken)
      if (shouldSetUser) {
        setUserFromToken(localAuthToken)
      }
    }
  }

  const setLoginRedirect = (newLoginRedirect = '') => {
    _setLoginRedirect(newLoginRedirect)
    tempSettings.set('loginRedirect', newLoginRedirect)
  }

  const setSkipRedirect = (newSkipRedirect = '') => {
    _setSkipRedirect(newSkipRedirect)
    tempSettings.set('skipRedirect', newSkipRedirect)
  }

  const setIsEmbedded = (newIsEmbedded = false) => {
    _setIsEmbedded(newIsEmbedded)
    tempSettings.set('isEmbedded', newIsEmbedded.toString())
  }

  const setSkipLogin = (newSkipLogin: boolean) => {
    _setSkipLogin(newSkipLogin)
    if (newSkipLogin) {
      tempSettings.set('skipLogin', newSkipLogin.toString())
    } else {
      tempSettings.clear('skipLogin')
    }
  }

  const setCommunicationDown = (newCommunicationDown: boolean) => {
    _setCommunicationDown(newCommunicationDown)
    if (newCommunicationDown) {
      tempSettings.set('communicationDown', newCommunicationDown.toString())
    } else {
      tempSettings.clear('communicationDown')
    }
  }

  const setLastGoodUrl = (lastGoodUrlCandidate: string) => {
    if (typeof lastGoodUrlCandidate === 'string' && !lastGoodUrlCandidate.includes('/error')) {
      _setLastGoodUrl(lastGoodUrlCandidate)
      tempSettings.set('lastGoodUrl', lastGoodUrlCandidate)
    }
  }

  const setOrgId = (orgIdCandidate: string) => {
    if ((verifyGuid(orgIdCandidate) || orgIdCandidate === '') && orgId !== orgIdCandidate) {
      _setOrgId(orgIdCandidate)
      appSettings.set('orgId', orgIdCandidate)
    }
    if (orgIdCandidate && !verifyGuid(orgIdCandidate)) {
      const invalidMessage = `Invalid organization id passed in: ${orgIdCandidate}`
      debugErrorLogger(invalidMessage)
      faro?.api?.pushLog([invalidMessage], { level: LogLevel.ERROR })
    }
  }

  const setSubId = (subIdCandidate: string) => {
    if ((verifyGuid(subIdCandidate) || subIdCandidate === '') && subId !== subIdCandidate) {
      _setSubId(subIdCandidate)
      appSettings.set('subId', subIdCandidate)
    }
    if (subIdCandidate && !verifyGuid(subIdCandidate)) {
      const invalidMessage = `Invalid subscription id passed in: ${subIdCandidate}`
      debugErrorLogger(invalidMessage)
      faro?.api?.pushLog([invalidMessage], { level: LogLevel.ERROR })
    }
  }

  const setAssignmentId = (assignmentIdCandidate: string) => {
    if (assignmentIdCandidate && assignmentId !== assignmentIdCandidate) {
      _setAssignmentId(assignmentIdCandidate)
      tempSettings.set('assignmentId', assignmentIdCandidate)
    }
  }

  const setAssignmentArtifact = (assignmentArtifactCandidate: string) => {
    if (assignmentArtifactCandidate && assignmentArtifact !== assignmentArtifactCandidate) {
      _setAssignmentArtifact(assignmentArtifactCandidate)
      tempSettings.set('assignmentArtifact', assignmentArtifactCandidate)
    }
  }

  const getOrgId = () => {
    return orgId || appSettings.get('orgId') || ''
  }

  const getSubId = () => {
    return subId || appSettings.get('subId') || ''
  }

  const updateLoginInfo = (options: __CommunicationOptionsOptional = {}) => {
    const newLoginInfo = Object.assign({}, savedLoginInfo, options)
    setSavedLoginInfo(newLoginInfo)
  }

  const resetLoginInfo = () => {
    setSavedLoginInfo(Object.assign({}, defaultLoginInfo))
  }

  const updateUserAppSettings = (userSettings: __UserAppSettings) => {
    const updatedSettings = Object.assign({}, userAppSettings, userSettings)
    setUserAppSettings(updatedSettings)
    tempSettings.set('userSettings', JSON.stringify(updatedSettings))
  }

  const clearState = () => {
    appSettings.clear('user')
    appSettings.clear('clientId')
    tempSettings.set('userSettings', '')
    tempSettings.set('loginRedirect', '')
    tempSettings.set('lastGoodUrl', '')
    tempSettings.clear('skipLogin')
    tempSettings.clear('communicationDown')
    tempSettings.set('isEmbedded', 'false')
    appSettings.clear('orgId')
    appSettings.clear('subId')
    tempSettings.set('assignmentId', '')
    tempSettings.set('assignmentArtifact', '')

    _setOrgId('')
    _setSubId('')
    _setAssignmentId('')
    _setAssignmentArtifact('')
    clearAppAccessToken()
    setShouldReset(false)
    setAuthToken('')
    setCurrentUser({ type: 'custom' })
    _setCurrentUser(undefined)
    resetLoginInfo()
  }

  const verifyToken = (patientId?: string) => {
    patientId = patientId || currentUser?.id
    let authenticated = false
    const tokenToUse = storedToken || authToken
    if (tokenToUse) {
      const parsedToken = parseToken(tokenToUse)
      const tokenInfo = getTokenExpirationInfo(tokenToUse)
      updateTimeoutDateTime(tokenToUse)
      authenticated = Boolean(
        [theUnPatientId, patientId].includes(parsedToken.sub) && !tokenInfo.expired,
      )
    }
    return authenticated
  }

  const purgeContextSettings = () => {
    clearState()
    appSettings.purge()
  }

  let startupState = {
    timeoutDateTime,
    authToken,
    setAuthToken,
    verifyToken,
    currentUser,
    setCurrentUser,
    setUserFromToken,
    loginInfo: savedLoginInfo,
    updateLoginInfo,
    resetLoginInfo,
    loginRedirect,
    setLoginRedirect,
    skipRedirect,
    setSkipRedirect,
    lastGoodUrl,
    setLastGoodUrl,
    skipLogin,
    setSkipLogin,
    communicationDown,
    setCommunicationDown,
    userAppSettings,
    updateUserAppSettings,
    isEmbedded,
    setIsEmbedded,
    setShouldReset,
    setErrors,
    setWarnings,
    purgeContextSettings,
    orgId: getOrgId(),
    setOrgId,
    subId: getSubId(),
    setSubId,
    assignmentId,
    setAssignmentId,
    assignmentArtifact,
    setAssignmentArtifact
  }

  const resurrectState = () => {
    const appAccessToken = getAppAccessToken()
    const parsedToken = parseToken(appAccessToken)
    const expireInformation = getTokenExpirationInfo(appAccessToken)

    debugLogger('Restore app state from storage')
    debugLogger('Restore auth token')
    if (!authToken && appAccessToken && !expireInformation.expired) {
      setAuthToken(appAccessToken)
    }

    debugLogger('Reinitialize current user')
    if (!currentUser) {
      const storedUser = appSettings.get('user')
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser)
        if (parsedUser.id && parsedToken.sub === parsedUser.id && !expireInformation.expired) {
          setCurrentUser(parsedUser)
        }
      }
    }

    const storedSkipLogin = tempSettings.get('skipLogin')
    if (storedSkipLogin === 'true') {
      _setSkipLogin(true)
    }

    const storedCommunicationDown = tempSettings.get('communicationDown')
    if (storedCommunicationDown === 'true') {
      _setCommunicationDown(true)
    }

    const storedIsEmbedded = tempSettings.get('isEmbedded')
    if (storedIsEmbedded === 'true') {
      _setIsEmbedded(true)
    }

    if (!loginRedirect) {
      const storedLoginRedirect = tempSettings.get('loginRedirect')
      setLoginRedirect(storedLoginRedirect || '')
    }

    if (!skipRedirect) {
      const storedSkipRedirect = tempSettings.get('skipRedirect')
      setSkipRedirect(storedSkipRedirect || '')
    }

    if (!lastGoodUrl) {
      const storedLastGoodUrl = tempSettings.get('lastGoodUrl')
      setLastGoodUrl(storedLastGoodUrl || '')
    }

    if (!orgId) {
      const storedOrgId = appSettings.get('orgId') || ''
      setOrgId(storedOrgId)
    }

    if (!subId) {
      const storedSubId = appSettings.get('subId') || ''
      setSubId(storedSubId)
    }

    if (!assignmentId) {
      const storedAssignmentId = tempSettings.get('assignmentId')
      setAssignmentId(storedAssignmentId || '')
    }

    if (!assignmentArtifact) {
      const storedAssignmentArtifact = tempSettings.get('assignmentArtifact')
      setAssignmentArtifact(storedAssignmentArtifact || '')
    }

    debugLogger('Initialize feature state')
    const storedUserSettings = tempSettings.get('userSettings')
    if (storedUserSettings) {
      setUserAppSettings(JSON.parse(storedUserSettings))
    } else {
      setUserAppSettings(defaultUserAppSettings)
    }
    initializeFeatures()
  }

  useEffect(() => {
    resurrectState()
  }, [])

  useEffect(() => {
    if (shouldReset) {
      clearState()
    }
  }, [shouldReset])

  const renderStatusMessages = () => {
    return !errors.length && !warnings.length ? null : (
      <div className={styles.messages}>
        {!errors.length ? null : (
          <div className={`${styles.messageList} ${styles.errors}`}>
            <ul>
              {errors.map((err, i) => (
                <li key={`error${i}`} dangerouslySetInnerHTML={{ __html: err }} />
              ))}
            </ul>
          </div>
        )}
        {!warnings.length ? null : (
          <div className={`${styles.messageList} ${styles.warnings}`}>
            <ul>
              {warnings.map((err, i) => (
                <li key={`warning${i}`} dangerouslySetInnerHTML={{ __html: err }} />
              ))}
            </ul>
          </div>
        )}
        <button
          className={classNames(styles.closeMessages, 'hw-btn', 'unstyled')}
          id='status-close-button'
          onClick={() => {
            setWarnings([])
            setErrors([])
          }}
          aria-label={Localize('Close')}
          title={Localize('Close')}
        >
          <IconX theme='light' />
        </button>
      </div>
    )
  }

  return (
    <AppContext.Provider value={startupState}>
      {renderStatusMessages()}
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}

export default AppWrapper
