import { debugLogger } from 'common'
import { getEnvironment } from 'config'
import { getLocalStorage } from 'storageFactory'

export const localFeatures: Feature[] = [
  {
    id: 'useCustomVideoPlayer',
    label: 'Use the custom video player',
    enabled: false,
    group: 'Video',
  },
  {
    id: 'showPseudoBranding',
    label: 'Show the pseudo branding',
    enabled: false,
    group: 'Branding',
    reload: true,
  },
  {
    id: 'showApiBrandingDefault',
    label: 'Show the API branding theme',
    enabled: false,
    group: 'Branding',
    reliesOn: 'showPseudoBranding',
    radioGroup: 'showPseudoBrandingTheme',
    reload: true,
  },
  {
    id: 'showPseudoBrandingWmd',
    label: 'Show WMDI branding theme',
    enabled: false,
    group: 'Branding',
    reliesOn: 'showPseudoBranding',
    radioGroup: 'showPseudoBrandingTheme',
    reload: true,
  },
  {
    id: 'showPseudoBrandingBlue',
    label: 'Show the Blues 1 branding theme',
    enabled: false,
    group: 'Branding',
    reliesOn: 'showPseudoBranding',
    radioGroup: 'showPseudoBrandingTheme',
    reload: true,
  },
  {
    id: 'showPseudoBrandingBlue2',
    label: 'Show the Blues 2 branding theme',
    enabled: false,
    group: 'Branding',
    reliesOn: 'showPseudoBranding',
    radioGroup: 'showPseudoBrandingTheme',
    reload: true,
  },
  {
    id: 'showPseudoBrandingDark',
    label: 'Show the Dark branding theme',
    enabled: false,
    group: 'Branding',
    reliesOn: 'showPseudoBranding',
    radioGroup: 'showPseudoBrandingTheme',
    reload: true,
  },
  {
    id: 'showPseudoBrandingGawdAwful',
    label: 'Show the Garish (high-contrast) branding theme',
    enabled: false,
    group: 'Branding',
    reliesOn: 'showPseudoBranding',
    radioGroup: 'showPseudoBrandingTheme',
    reload: true,
  },
  {
    id: 'showDocTypeInSearch',
    label: 'Show document type next to title in search',
    enabled: false,
    group: 'Search',
  },
  {
    id: 'infiniteSearchScrollOn',
    label: 'Turn on infinite search scrolling',
    enabled: false,
    group: 'Search',
  },
  {
    id: 'hideNonLearningObjectives',
    label: 'Hide automated description (not learning objectives) for search and the dashboard',
    enabled: false,
    group: 'Learning Objectives',
  },
  {
    id: 'onlyShowEnglishLearningObjectives',
    label:
      'Only show Learning Objectives for assigned English content. (Learning objectives have not all been translated into other languages, but most abstracts have been)',
    enabled: true,
    group: 'Learning Objectives',
  },
  {
    id: 'showMultiLanguageSwitcher',
    label: 'Show multi language options for switching UI localization',
    enabled: false,
    group: 'Multi Language',
  },
  {
    id: 'reduceTimeToTimeoutWarning',
    label: 'Reduce the time to see the timeout warning',
    enabled: false,
    group: 'Debugging',
  },
  {
    id: 'forceCommunicationFailure',
    label: 'Emulate a failure with communication endpoint to skip login',
    enabled: false,
    group: 'Debugging',
  },
  {
    id: 'simulateNoAssignments',
    label: 'Simulate no assignments in dashboard',
    enabled: false,
    group: 'Debugging',
  },
  {
    id: 'showInvalidLinks',
    label: 'Show invalid links in content',
    enabled: false,
    group: 'Debugging',
  },
  {
    id: 'showEditedIcon',
    label: 'Show edited icon on dashboard for edited content.',
    enabled: getEnvironment().dev,
    group: 'Debugging',
  },
]

export const featuresKey = 'features_state'

export type Feature = {
  id: string
  label: string
  enabled: boolean
  group?: string
  reload?: boolean
  goLive?: Date // Example: goLive: new Date('2025-02-05T03:00:00.000Z'),
  reliesOn?: string
  radioGroup?: string
}

const getFeatureState = (): { [key: string]: boolean } => {
  if (typeof window === 'undefined') return {}
  const storage = getLocalStorage()
  return JSON.parse(storage.getItem(featuresKey) || '{}')
}

const setFeatureState = (storedFeatures: { [key: string]: boolean }) => {
  if (typeof window === 'undefined') return
  const storage = getLocalStorage()
  if (storedFeatures === null) storage.setItem(featuresKey, '{}')
  storage.setItem(featuresKey, JSON.stringify(storedFeatures))
}

export const updateFeature = (featureId: string, featureEnabled: boolean) => {
  if (featureId) {
    const featureState = getFeatureState()
    featureState[featureId] = featureEnabled
    setFeatureState(featureState)
  }
}

export const initializeFeatures = (features: Feature[] = localFeatures) => {
  if (typeof global === 'undefined') return
  const storedFeatureState = getFeatureState()
  for (let id in features) {
    if (!(features[id].id in storedFeatureState)) {
      updateFeature(features[id].id, features[id].enabled)
    }
  }
}

export const resetFeatureState = () => {
  if (typeof window === 'undefined') return
  const storage = getLocalStorage()
  storage.setItem(featuresKey, '{}')
  window.location.reload()
}

const getCurrentFeature = (featureId: string, features: Feature[] = localFeatures): Feature => {
  const currentFeature = features.filter(feature => feature.id === featureId)
  return currentFeature?.[0] || {}
}

export const isPastGoLiveDate = (featureId: string, features: Feature[] = localFeatures) => {
  const currentFeature = getCurrentFeature(featureId, features)
  if (currentFeature?.goLive) {
    const now = new Date()
    if (now > currentFeature.goLive) {
      return true
    }
  }
  return false
}

export const testFeatureActive = (featureId: string): boolean => {
  const featureState = getFeatureState()
  return featureState[featureId] || isPastGoLiveDate(featureId)
}

/**
 * Usage
 * Query Key = 'features'
 * values =
 *   all = turns on all local features
 *   none = turns off all local features
 *
 *   Individual features:
 *   featureId = the unique id of the feature you wish to set
 *   featureEnabled = To turn the feature on set to 'true' or 'on'
 *     or leave blank. To turn the feature off set to 'false' or 'off'
 *   featureId and featureEnabled are joined by a colon (i.e., featureId:false)
 * id:enabled pairs are separated by a pipe character '|'
 * Example:
 *   ?features=featureIdOne:false|featureIdTwo:true|featureIdThree|featureFour:on|featureFive:off
 * Sets:
 *   featureIdOne off
 *   featureIdTwo on
 *   featureIdThree on
 *   featureIdFour on
 *   featureIdFive off
 */
export const setQueryStringFeatures = (
  searchParams: string = window?.location?.search,
  features: Feature[] = localFeatures,
) => {
  if (searchParams) {
    const urlParams = new URLSearchParams(searchParams)
    const featuresQueryValue = urlParams.get('features')

    if (featuresQueryValue === 'none') {
      features.forEach(feature => {
        updateFeature(feature.id, false)
      })
    } else if (featuresQueryValue === 'all') {
      features.forEach(feature => {
        updateFeature(feature.id, true)
      })
    } else {
      const featurePairs = featuresQueryValue?.split('|')

      featurePairs?.forEach(featurePair => {
        const featureParts = featurePair.split(':')
        const featureId = featureParts[0]
        const featureEnabled =
          typeof featureParts[1] === 'undefined' ? true : ['true', 'on'].includes(featureParts[1])
        updateFeature(featureId, featureEnabled)
      })
    }
  } else {
    debugLogger('No search params passed in')
  }
}
