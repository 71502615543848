import {
  createContext,
  useContext,
  useState,
  FunctionComponent,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import { SearchClient } from 'algoliasearch/lite'
import { SearchIndex } from 'algoliasearch'
import { algoliaSearchSettings } from 'config'
import { FilterHandlerType, FilterPropertiesType } from 'filters/filter.config'
import useLookupContextCommon from './useLookupContextCommon'
import StorageFactory, { getSessionStorage } from 'storageFactory'
import { debugErrorLogger } from 'common'

const maxSearchHistoryDisplayLength = 3
const defaultHitsPerPage = 10
const commonSearchAttributesToRetrieve = [
  'licensedContent',
  'content.id',
  'content.hwid',
  'content.type',
  'content.title',
  'content.description',
  'content.duration',
  'content.availableLocalizations',
  'content.learningObjective',
  'content.thumbnail',
  'content.inHouse',
  'content.edited',
  'content.interactive',
  'content.source',
  'content.version',
  'content.localization',
  'content.htmlVersion',
]

export const searchContentAttributes = commonSearchAttributesToRetrieve
  .filter(sar => sar.startsWith('content.'))
  .map(sar => sar.replace('content.', ''))

const searchSettingsKey = 'search_settings'
type SearchSettingsType = 'history'
const searchSettings = new StorageFactory<SearchSettingsType>(
  searchSettingsKey,
  getSessionStorage(),
)
export class SearchHistory {
  private searchHistory: string[]
  private searchHistoryLength: number = maxSearchHistoryDisplayLength

  constructor(initialHistory: string[] = []) {
    this.searchHistory = initialHistory
  }

  private persist() {
    searchSettings.set('history', JSON.stringify(this.searchHistory))
  }

  setHistoryLength(length: number) {
    if (length <= 0) {
      this.searchHistoryLength = 1
    } else {
      this.searchHistoryLength = length
    }
    this.persist()
  }

  getValues(length: number = this.searchHistoryLength) {
    const values: string[] = []
    const maxValues = Math.min(length, this.searchHistory.length)
    for (let vi = 0; vi < maxValues; vi++) {
      values.push(this.searchHistory[vi])
    }
    return values
  }

  add(newValue: string) {
    if (newValue?.length) {
      const newValueLower = newValue.toLocaleLowerCase().trim()
      this.remove(newValueLower)
      this.searchHistory.unshift(newValueLower)
    }
    this.persist()
  }

  remove(existingValue: string) {
    const existingValueLower = existingValue.toLocaleLowerCase()
    const valueIndex = existingValueLower ? this.searchHistory.indexOf(existingValueLower) : -1
    if (valueIndex !== -1) {
      this.searchHistory.splice(valueIndex, 1)
    }
    this.persist()
  }

  reinitialize(initialHistory: string[]) {
    if (Array.isArray(initialHistory) && initialHistory.length > 0) {
      this.clear()
      this.searchHistory = initialHistory
      this.persist()
    }
  }

  clear() {
    this.searchHistory = []
    this.persist()
  }
}

const historyImplementation = new SearchHistory()

export interface SearchRequestOptionsType {
  filters?: string
  enablePersonalization?: boolean
  hitsPerPage?: number
  distinct?: boolean
  facetingAfterDistinct?: boolean
  analytics?: boolean
  clickAnalytics?: boolean
  analyticsTags?: string[]
  page?: number
  offset?: number
  length?: number
  attributesToRetrieve?: string[]
}

interface SearchContextType {
  searchReady: boolean
  searchError: Error | undefined
  searchClient: SearchClient | null
  wordsIndex: string
  suggestionsIndex: string
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  rigidSearchTerm: string //used for search page to prevent searching while typing
  setRigidSearchTerm: (searchTerm: string) => void
  filterProperties: FilterPropertiesType
  filterHandler?: FilterHandlerType
  searchHistory: string[]
  getSearchResults: (
    searchTerm: string,
    requestOptions: SearchRequestOptionsType,
    callback: (results: __AlgoliaHit[], nbHits: number) => void,
  ) => void
  getSuggestionsResults: (
    searchTerm: string,
    requestOptions: SearchRequestOptionsType,
    callback: (results: __ConceptHit[], nbHits: number) => void,
  ) => void
  setSearchHistory: (history: string[]) => void
  getSearchHistory: (count?: number) => string[]
  addSearchHistory: (valueToAdd: string) => void
  removeSearchHistory: (valueToRemove: string) => void
  clearSearchHistory: () => void
  setSearchHistoryDisplayLength: (length: number) => void
}

const defaultState: SearchContextType = {
  searchReady: false,
  searchError: undefined,
  searchTerm: '',
  setSearchTerm: (searchTerm: string | undefined) => {
    if (typeof window !== 'undefined') {
      debugErrorLogger(`Search term provider not set up. Searched for: ${searchTerm}`)
    }
  },
  rigidSearchTerm: '',
  setRigidSearchTerm: (searchTerm: string | undefined) => {
    if (typeof window !== 'undefined') {
      debugErrorLogger(`Rigid search term provider not set up. Searched for: ${searchTerm}`)
    }
  },
  searchClient: null,
  searchHistory: [],
  wordsIndex: '',
  suggestionsIndex: '',
  filterProperties: {
    aisFilterString: '',
    aisFilterList: [],
    aisOptionalList: [],
    isInDefaultState: false,
    activeFilterCount: 0,
  },
  getSearchResults: (
    searchTerm: string,
    requestOptions: SearchRequestOptionsType,
    callback: (results: __AlgoliaHit[], nbHits: number) => void,
  ) => {
    debugErrorLogger(
      `Function not implemented: searchResults. ${searchTerm}, ${
        requestOptions ? JSON.stringify(requestOptions) : 'none'
      } - ${typeof callback}`,
    )
  },
  getSuggestionsResults: (
    searchTerm: string,
    requestOptions: SearchRequestOptionsType,
    callback: (results: __ConceptHit[], nbHits: number) => void,
  ) => {
    debugErrorLogger(
      `Function not implemented: searchResults. ${searchTerm}, ${
        requestOptions ? JSON.stringify(requestOptions) : 'none'
      } - ${typeof callback}`,
    )
  },
  setSearchHistory: (history: string[]): void => {
    debugErrorLogger('Function not implemented: setSearchHistory. ' + history?.join(','))
  },
  getSearchHistory: (count?: number | undefined): string[] => {
    debugErrorLogger('Function not implemented: getSearchHistory. ' + count)
    return []
  },
  setSearchHistoryDisplayLength: (length?: number) => {
    debugErrorLogger('Function not implemented: setSearchHistoryDisplayLength. ' + length)
    return []
  },
  addSearchHistory: (valueToAdd: string): void => {
    debugErrorLogger('Function not implemented: addSearchHistory. ' + valueToAdd)
  },
  removeSearchHistory: (valueToRemove: string): void => {
    debugErrorLogger('Function not implemented: removeSearchHistory. ' + valueToRemove)
  },
  clearSearchHistory: (): void => {
    debugErrorLogger('Function not implemented: clearSearchHistory. ')
  },
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

const SearchContext = createContext<SearchContextType>(defaultState)

const SearchContextWrapper: FunctionComponent<Props> = ({ children }) => {
  const {
    lookupReady,
    lookupError,
    searchClient,
    searchIndex,
    wordsIndex,
    suggestionsIndex,
    filterProperties,
    filterHandler,
    clearLookupStoredState,
  } = useLookupContextCommon({
    algoliaSettings: algoliaSearchSettings,
    filterLabel: 'globalSearch',
    audience: 'consumer',
  })

  const [update, forceUpdate] = useReducer(x => x + 1, 0)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [rigidSearchTerm, setRigidSearchTerm] = useState<string>('')
  const [searchHistoryDisplayLength, setSearchHistoryDisplayLength] = useState<number>(
    maxSearchHistoryDisplayLength,
  )

  const searchHistory = useMemo(() => {
    return historyImplementation.getValues()
  }, [update, searchHistoryDisplayLength])

  const searchReady = useMemo(() => {
    return Boolean(lookupReady && searchClient && searchIndex)
  }, [lookupReady, searchClient, searchIndex])

  // Restore search history if it exists
  useEffect(() => {
    const storedHistory = searchSettings.get('history')
    if (
      !searchHistory.length &&
      storedHistory &&
      storedHistory.startsWith('[') &&
      storedHistory.endsWith(']')
    ) {
      const historyArr = JSON.parse(storedHistory)
      if (Array.isArray(historyArr) && historyArr.length) {
        historyImplementation.reinitialize(historyArr)
        forceUpdate()
      }
    }
  }, [searchHistory])

  useEffect(() => {
    historyImplementation.setHistoryLength(searchHistoryDisplayLength)
  }, [searchHistoryDisplayLength])

  const clearSearchHistory = () => {
    clearLookupStoredState?.()
    historyImplementation.clear()
    setSearchTerm('')
    setRigidSearchTerm('')
    forceUpdate()
  }

  const setSearchHistory = (history: string[]) => {
    historyImplementation.reinitialize(history)
    forceUpdate()
  }
  const getSearchHistory = (count?: number) => {
    return historyImplementation.getValues(count)
  }
  const addSearchHistory = (valueToAdd: string) => {
    historyImplementation.add(valueToAdd)
    forceUpdate()
  }
  const removeSearchHistory = (valueToRemove: string) => {
    historyImplementation.remove(valueToRemove)
    forceUpdate()
  }

  const getSearchResults = (
    searchTerm: string,
    requestOptions: SearchRequestOptionsType,
    callback: (results: __AlgoliaHit[], nbHits: number) => void,
  ) => {
    const defaultOptions: SearchRequestOptionsType = {
      filters: filterProperties.aisFilterString,
      hitsPerPage: defaultHitsPerPage,
      facetingAfterDistinct: true,
      distinct: true,
      attributesToRetrieve: commonSearchAttributesToRetrieve,
    }
    const options = Object.assign({}, defaultOptions, requestOptions)
    if (searchIndex && searchTerm) {
      searchIndex.search(searchTerm, options).then(({ hits, nbHits }) => {
        callback(hits as __AlgoliaHit[], nbHits)
      })
    } else {
      callback([], 0)
    }
  }

  const getSuggestionsResults = (
    searchTerm: string,
    requestOptions: SearchRequestOptionsType,
    callback: (results: __ConceptHit[], nbHits: number) => void,
  ) => {
    const defaultOptions: SearchRequestOptionsType = {
      filters: '',
      hitsPerPage: defaultHitsPerPage,
    }
    const options = Object.assign({}, defaultOptions, requestOptions)
    if (searchClient) {
      const newSuggestionsIndex: SearchIndex = searchClient.initIndex(
        suggestionsIndex,
      ) as SearchIndex
      if (callback && newSuggestionsIndex && (searchTerm || searchTerm === '')) {
        newSuggestionsIndex.search(searchTerm, options).then(({ hits, nbHits }) => {
          callback(hits as __ConceptHit[], nbHits)
        })
      }
    }
  }

  const searchState: SearchContextType = {
    searchReady,
    searchError: lookupError,
    searchTerm,
    setSearchTerm,
    rigidSearchTerm,
    setRigidSearchTerm,
    searchClient,
    wordsIndex,
    suggestionsIndex,
    filterHandler,
    filterProperties,
    searchHistory,
    getSearchResults,
    getSuggestionsResults,
    setSearchHistory,
    getSearchHistory,
    clearSearchHistory,
    addSearchHistory,
    removeSearchHistory,
    setSearchHistoryDisplayLength,
  }

  return <SearchContext.Provider value={searchState}>{children}</SearchContext.Provider>
}

export const useSearchContext = () => {
  return useContext(SearchContext)
}

export type { SearchContextType }

export default SearchContextWrapper
