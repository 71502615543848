export const minUserAge = 0
export const maxUserAge = 110

export enum ContentTypes {
  piAftervisit = 'PI Aftervisit',
  cdc = 'CDC Document',
  fdb = 'Fdb',
  multum = 'Multum Document',
  piPostOp = 'PI Post-Op',
  piPreOp = 'PI Pre-Op',
  quicklist = 'Quick List',
  video = 'Video',
  infoConcept = 'hwInfoConcept',
  focus = 'Focus',
  csp = 'Care Support Page',
  whenToCall = 'hwWhenToCall',
  stem = 'hwStem',
  legacy = 'hwLegacy',
  task = 'hwTask',
  anatomysketch = 'Anatomy Sketch',
  action = 'hwAction',
  message = 'hwMessage',
  media = 'hwMedia',
  actionset = 'Actionset',
  dp = 'Decision Point',
  surgicalDetail = 'Surgical Detail',
  mini = 'Mini',
  medicalTest = 'Medical Test',
  symptom = 'Symptom',
  special = 'Special',
  cam = 'CAM Document',
  worksConsulted = 'hwWorksConsulted',
  otherDetail = 'Other Detail',
  major = 'Major',
  nciDocument = 'NCI Document',
  iaDefinition = 'hwIADefinition',
  mediaTask = 'hwMediaTask',
  conditionCenter = 'Condition Center',
  mediaSeries = 'hwMediaSeries',
  iTool = 'Interactive Tool',
  story = 'hwStory',
  iaDisposition = 'hwIADisposition',
  decisionAssessment = 'hwDecisionAssessment',
  mediaTaskSeries = 'hwMediaTaskSeries',
  decisionComponent = 'hwDecisionComponent',
  visualList = 'hwVisualList',
  drugDetail = 'Drug Detail',
  campaign = 'Campaign',
  reference = 'hwReference',
  testDetail = 'Test Detail',
  nci = 'Nci',
  frame = 'Frame',
  healthRisks = 'hwHealthRisks',
  definition = 'Definition',
  slideshow = 'hwSlideshow',
}

export const clinicalDocumentFilters = [
  ContentTypes.fdb,
  ContentTypes.piAftervisit,
  ContentTypes.anatomysketch,
  ContentTypes.cdc,
  ContentTypes.multum,
  ContentTypes.piPostOp,
  ContentTypes.piPreOp,
  ContentTypes.quicklist,
  ContentTypes.surgicalDetail,
  ContentTypes.medicalTest,
  ContentTypes.drugDetail,
  ContentTypes.testDetail,
  ContentTypes.nci,
  ContentTypes.nciDocument,
]

export const consumerDocumentFilters = [
  ContentTypes.actionset,
  ContentTypes.cdc,
  ContentTypes.fdb,
  ContentTypes.major,
  ContentTypes.medicalTest,
  ContentTypes.mini,
  ContentTypes.nciDocument,
  ContentTypes.otherDetail,
  ContentTypes.testDetail,
  ContentTypes.surgicalDetail,
  ContentTypes.focus,
  ContentTypes.special,
]

export const futureDocumentFilters = [
  ContentTypes.symptom,
  ContentTypes.multum,
  ContentTypes.quicklist,
  ContentTypes.infoConcept,
  ContentTypes.csp,
  ContentTypes.whenToCall,
  ContentTypes.stem,
  ContentTypes.task,
  ContentTypes.anatomysketch,
  ContentTypes.action,
  ContentTypes.message,
  ContentTypes.cam,
  ContentTypes.worksConsulted,
  ContentTypes.iaDefinition,
  ContentTypes.mediaTask,
  ContentTypes.conditionCenter,
  ContentTypes.mediaSeries,
  ContentTypes.story,
  ContentTypes.mediaTaskSeries,
  ContentTypes.visualList,
  ContentTypes.drugDetail,
  ContentTypes.reference,
  ContentTypes.testDetail,
  ContentTypes.frame,
  ContentTypes.healthRisks,
  ContentTypes.definition,
  ContentTypes.slideshow,
  ContentTypes.dp,
  ContentTypes.symptom,
  ContentTypes.iTool,
]

export type FilterPropertiesType = {
  aisFilterString: string
  aisFilterList: string[]
  aisOptionalList: string[]
  isInDefaultState: boolean
  activeFilterCount: number
}

export type FilterHandlerType = {
  initialize: (filters?: string | (string | number)[] | undefined) => void
  isInitialized: () => boolean
  merge: (
    filters?: (string | number | undefined)[] | string,
    mergeAttributes?: string[] | undefined,
    replaceAttributes?: string[] | undefined,
  ) => void
  add: (
    attribute: string | undefined,
    labelsCandidate: string | number | undefined | (string | number)[],
  ) => void
  remove: (
    attribute: string | undefined,
    labelsCandidate?: string | number | undefined | (string | number)[],
  ) => void
  toggle: (
    attribute: string | undefined,
    labelsCandidate: string | number | undefined | (string | number)[],
  ) => void
  reset: (attributeLabel?: string | undefined) => void
  isActive: (attribute: string | undefined, labelsCandidate: string | number | undefined) => boolean
  getFilters: (attribute: string | undefined) => (string | number | undefined)[]
  setDebug: (debugOn: boolean) => void
}

export const attributeLabels: { [key: string]: string } = {
  assetType: 'assetType',
  rightAge: 'content.minAge',
  leftAge: 'content.maxAge',
  sex: 'content.gender',
  concepts: 'concept.label',
  childConcepts: 'concept.taxonomy.children.label',
  parentConcepts: 'concept.taxonomy.parents.label',
  contentType: 'content.type',
  specialty: 'concept.specialties',
  delivery: 'content.delivery',
  keywords1: 'concept.keywords.r1',
  keywords2: 'concept.keywords.r2',
  audience: 'content.audience',
  localizations: 'content.availableLocalizations',
  product: 'content.product',
  id: 'content.id',
  hwid: 'content.hwid',
  displayLanguage: 'content.localization',
  interactiveContent: 'content.interactive',
  hwManagedAsset: 'content.hma',
}

export const filterManagerIds: { [key: string]: string } = {
  global: 'GLOBAL_FILTERS',
  optional: 'OPTIONAL_FILTERS',
  launch: 'LAUNCH_FILTERS',
  favorites: 'FAVORITES_FILTERS',
}

export const blacklistHwids = [
  'aa15771',
  'aa20801',
  'aa21125',
  'aa86932',
  'tj1498',
  'tj2475',
  'tj2532',
  'tj2794',
  'tp23271',
  'tw9636',
  'ug2542',
  'ug3345',
  'ug5186',
  'uh1814',
  'uq2638',
  'uq2696',
  'uq2705',
  'zm2258',
  'zx3020',
  'zx3021',
  'bs1005share',
  'bs1005',
  'tu6657',
  'share',
  'tv6654',
  'tv6655',
  'tv6656',
  'tv6657',
]

export const greyListHwids = [
  'aa114499',
  'aa129142',
  'aa137606',
  'aa151402',
  'aa154666',
  'aa15784',
  'aa86803',
  'aa86905',
  'aa86936',
  'aa95576',
  'abg7017',
  'abl1753',
  'abn2339',
  'abp9905',
  'abp9957',
  'abp9959',
  'abp9961',
  'av2494',
  'tb1867',
  'tb1868',
  'tj2461',
  'tj2602',
  'tm4434',
  'tm6566',
  'tp17619',
  'tp23272',
  'tp23273',
  'ug1255',
  'ug1344',
  'ug3271',
  'ug4392',
  'ug4973',
  'uh1813',
  'zd1600',
  'zm2253',
  'zm2256',
  'zm2257',
  'zm2259',
  'zm2261',
  'zm2262',
  'zx3175',
  'zx3727',
  'zx4012',
]
