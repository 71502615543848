import StorageFactory, {
  MockStorage,
  StorageType,
  getLocalStorage,
  getSessionStorage,
} from 'storageFactory'
const CookieStorage = MockStorage
export { MockStorage, CookieStorage, getLocalStorage, getSessionStorage }
export type { StorageType }
export default StorageFactory
