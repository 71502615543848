// This file is actually being used
import '../styles/globals.css'
import '../styles/natives.scss'
import '../styles/hw-content.scss'
import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import Head from 'next/head'
import getConfig from 'next/config'
import { FaroErrorBoundary, withFaroProfiler } from '@grafana/faro-react'
import { useEffect } from 'react'
import { initializeFaro } from 'faro/initialize'
import AppContextWrapper from 'pageComponents/AppWrapperContext'
const { publicRuntimeConfig } = getConfig() || { publicRuntimeConfig: {} }
function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (
      publicRuntimeConfig.K8S_TELEMETRY_COLLECTOR_URL &&
      publicRuntimeConfig.TELEMETRY_COLLECTOR_API_KEY
    ) {
      initializeFaro()
    }
  }, [])

  return (
    <FaroErrorBoundary>
      <AppContextWrapper>
        <Head>
          <meta name='viewport' content='width=device-width, initial-scale=1, minimum-scale=1' />
          <meta name='theme-color' content='#000000' />
          <meta name='description' content='Healthwise Education Module' />
          <meta property='og:title' content='Healthwise Education Module' />
          <meta
            property='og:image'
            content='https://www.healthwise.org/App_Themes/dotOrg/images/logo_social.png'
          />
          <title>Healthwise Education Module</title>
        </Head>
        <Component {...pageProps} />
      </AppContextWrapper>
    </FaroErrorBoundary>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)

  return { ...appProps }
}

export default withFaroProfiler(MyApp)
