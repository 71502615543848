import { createContext, useContext, FunctionComponent, useMemo } from 'react'
import { SearchClient } from 'algoliasearch/lite'
import { algoliaSearchSettings } from 'config'
import { attributeLabels, FilterHandlerType, FilterPropertiesType } from 'filters/filter.config'
import { debugLogger } from 'common'
import { testFeatureActive } from 'features'
import useLookupContextCommon from './useLookupContextCommon'
import { FilterStringBuilder } from 'filters/filter.string.builder'

interface MetadataContextType {
  metadataReady: boolean
  metadataError: Error | undefined
  searchClient: SearchClient | null
  wordsIndex: string
  suggestionsIndex: string
  filterProperties: FilterPropertiesType
  filterHandler?: FilterHandlerType
  clearMetadataStoredState?: () => void
  getContentMetadata: (
    hwid: string,
    desiredLoc: __Localization,
    callback: (metadata: __SearchContentMetadataType) => void,
  ) => void
}

const defaultState: MetadataContextType = {
  metadataReady: false,
  metadataError: undefined,
  searchClient: null,
  wordsIndex: '',
  suggestionsIndex: '',
  filterProperties: {
    aisFilterString: '',
    aisFilterList: [],
    aisOptionalList: [],
    isInDefaultState: false,
    activeFilterCount: 0,
  },
  getContentMetadata: (
    hwid: string,
    desiredLoc: __Localization,
    callback: (metadata: __SearchContentMetadataType) => void,
  ) => {
    debugLogger('No implementation for getContentMetadata', hwid, desiredLoc, callback)
  },
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

const MetadataContext = createContext<MetadataContextType>(defaultState)

const MetadataContextWrapper: FunctionComponent<Props> = ({ children }) => {
  const {
    lookupReady,
    lookupError,
    searchClient,
    wordsIndex,
    suggestionsIndex,
    filterProperties,
    filterHandler,
    searchIndex,
    clearLookupStoredState,
  } = useLookupContextCommon({
    algoliaSettings: algoliaSearchSettings,
    filterLabel: 'globalMetadata',
    audience: 'advise',
  })

  const metadataReady = useMemo(() => {
    return Boolean(lookupReady && searchClient && searchIndex)
  }, [lookupReady, searchClient, searchIndex])

  const getContentMetadata = (
    hwid: string,
    desiredLoc: __Localization,
    callback: (metadata: __SearchContentMetadataType) => void,
  ) => {
    const emptyMetadata: __SearchContentMetadataType = {
      hwid: hwid || '',
      abstract: '',
      title: '',
      loc: undefined,
      availableLocalizations: [],
      source: '',
      type: '',
      docType: '',
      thumbnail: '',
    }
    if (searchIndex && hwid) {
      const onlyShowEnglishLearningObjectives = testFeatureActive(
        'onlyShowEnglishLearningObjectives',
      )
      const hideNonLearningObjectives = testFeatureActive('hideNonLearningObjectives')

      // Handle when hwid is a searchable word (e.g. gas)
      const tempStringBuilder = new FilterStringBuilder()
      tempStringBuilder.initialize(filterProperties.aisFilterString || '')
      tempStringBuilder.add(attributeLabels.hwid, hwid)

      searchIndex
        .search(hwid, { filters: tempStringBuilder.aisFilterString, enablePersonalization: false })
        .then(({ hits }) => {
          if (hits.length >= 1) {
            const hit = (hits.find(h => {
              return (h as __AlgoliaHit).content?.localization === desiredLoc
            }) || hits[0]) as __AlgoliaHit
            const content: __AlgoliaHitContent = hit.content
            const loc = (content.localization || '') as __Localization
            const searchMetadata: __SearchContentMetadataType = {
              loc,
              hwid,
              abstract: '',
              title: content.title,
              availableLocalizations: content.availableLocalizations as __Localization[],
              source: content.source || '',
              type: content.type || '',
              docType: content.docType || '',
              thumbnail: content.thumbnail || '',
            }

            if (
              onlyShowEnglishLearningObjectives &&
              content.localization === 'en-us' &&
              content?.learningObjective
            ) {
              searchMetadata.abstract = content.learningObjective
            } else if (!onlyShowEnglishLearningObjectives && content?.learningObjective) {
              searchMetadata.abstract = content.learningObjective
            } else if (content?.description && !hideNonLearningObjectives) {
              searchMetadata.abstract = content.description
            }
            callback(searchMetadata)
          } else {
            callback(emptyMetadata)
          }
        })
    } else {
      callback(emptyMetadata)
    }
  }

  const searchState: MetadataContextType = {
    searchClient,
    wordsIndex,
    suggestionsIndex,
    filterHandler,
    filterProperties,
    getContentMetadata,
    metadataReady,
    clearMetadataStoredState: clearLookupStoredState,
    metadataError: lookupError,
  }

  return <MetadataContext.Provider value={searchState}>{children}</MetadataContext.Provider>
}

export const useMetadataContext = () => {
  return useContext(MetadataContext)
}

export type { MetadataContextType }

export default MetadataContextWrapper
