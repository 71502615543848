import { debugLogger, verifyLang } from 'common'
import { testFeatureActive } from 'features'
import {
  defaultCountryCode,
  defaultLocalization,
  displayLocalizations,
  getLangFromLoc,
  latestDisplayLocalizations,
} from '../config'

export enum dateFormats {
  medical = 'medical',
  medicalLong = 'medicalLong',
  month = 'month',
  standard = 'standard',
}

export type OnChangeEventType = {
  name: string
  event: (loc: __Localization, eventName: string) => void
}

export type LocalizerType = {
  localization: string | __Localization
  languageCode: __Language
  verbose: boolean
  getAge: (
    birthDate: Date | string | number | undefined,
    dateToCheck?: Date | string | number,
  ) => string
  setVerbose: (useVerbose: boolean) => void
  changeLocalization: (lang: string) => void
  Localize: (id: string, ...settings: any[]) => string
  LocalizeDate: (date: Date | string | undefined, format?: dateFormats) => string
  getLocalizationName: (lang: string) => LocalizationNameType | undefined
  verifyLang: (lang: string) => boolean
}

export type LocalizeListType = {
  [id: string]: { [loc: string]: string | string[] | object | boolean }
}

export type LocalizationNameType = {
  localization: string
  lang: string
  englishName: string
  nativeName: string
  tla: string
  short: string
  direction: 'rtl' | 'ltr' | string
}

export type LocalizeFormType = {
  future: string
  past: string
  plural: string
  present: string
  singular: string
  feminine: string
  masculine: string
}

export const initialLocalizer: LocalizerType = {
  localization: defaultLocalization,
  verbose: false,
  languageCode: getLangFromLoc(defaultLocalization),
  getAge: () => 'getAge method not initialized',
  setVerbose: () => {
    debugLogger('setVerbose method not initialized')
  },

  changeLocalization: () => {
    debugLogger('changeLocalization method not initialized')
  },
  Localize: () => 'Localize method not initialized',
  LocalizeDate: () => 'LocalizeDate method not initialized',
  getLocalizationName: () => {
    debugLogger('getLocalizationName method not initialized')
    return {
      localization: defaultLocalization,
      lang: 'getLocalizationName method not initialized',
      englishName: 'getLocalizationName method not initialized',
      nativeName: 'getLocalizationName method not initialized',
      tla: 'getLocalizationName method not initialized',
      short: 'getLocalizationName method not initialized',
      direction: 'ltr',
    }
  },
  verifyLang: () => {
    debugLogger('verifyLang method not initialized')
    return false
  },
}

const showMultiLanguageSwitcher = testFeatureActive('showMultiLanguageSwitcher')
const uiLocalizations = (): __Localization[] => {
  if (showMultiLanguageSwitcher) {
    return latestDisplayLocalizations
  }
  return displayLocalizations
}

export const getBrowserLocalization = (): __Localization =>
  (window?.navigator?.language || '').toLocaleLowerCase() as __Localization

const setHtmlLanguage = (lang: string) => {
  if (typeof document !== 'undefined' && verifyLang(lang)) {
    const $html = document.querySelector('html')
    if ($html) {
      $html.setAttribute('lang', getLangFromLoc(lang))
    }
  }
}

export const uxLangKey = 'desired-ux-lang'
export const setUxLang = (lang: string) => {
  const isValidLanguage = verifyLang(lang)
  if (typeof window !== 'undefined') {
    if (isValidLanguage) {
      if (lang.length === 2) {
        localStorage.setItem(uxLangKey, `${lang}-${defaultCountryCode}`)
      } else {
        localStorage.setItem(uxLangKey, lang)
      }
      setHtmlLanguage(lang)
    } else {
      localStorage.setItem(uxLangKey, defaultLocalization)
      setHtmlLanguage(defaultLocalization)
    }
  }
}

export const getUxLang = (verbose = false) => {
  const uxLang: string | false | null | __Localization =
    typeof global !== 'undefined' && global.localStorage.getItem(uxLangKey)
  if (uxLang) {
    return uxLang
  }

  verbose && debugLogger('Lang not found, trying browser language', defaultLocalization)
  const uxLoc = getBrowserLocalization()
  if (uxLoc && uiLocalizations().includes(uxLoc)) {
    setUxLang(uxLoc)
    return uxLoc
  }

  verbose && debugLogger('Lang not found, using default language', defaultLocalization)
  setUxLang(defaultLocalization)
  return defaultLocalization
}
