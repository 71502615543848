import { FilterStringBuilder, FilterStringBuilderSettings } from './filter.string.builder'
import { FilterPropertiesType, FilterHandlerType } from './filter.config'

const filterManagers: { [key: string]: FilterStringBuilder } = {}
const filterProperties: FilterPropertiesType = {
  aisFilterString: '',
  aisFilterList: [],
  aisOptionalList: [],
  isInDefaultState: true,
  activeFilterCount: 0,
}

const setAisFilter = (aisFilterString: string) => {
  filterProperties.aisFilterString = aisFilterString
}

const setFilterList = (aisFilterList: string[]) => {
  filterProperties.aisFilterList = aisFilterList
}

const setOptionalList = (aisOptionalList: string[]) => {
  filterProperties.aisOptionalList = aisOptionalList
}

const setIsInDefaultState = (isInDefaultState: boolean) => {
  filterProperties.isInDefaultState = isInDefaultState
}

const setActiveFilterCount = (activeFilterCount: number) => {
  filterProperties.activeFilterCount = activeFilterCount
}

const FilterManager = (id: string, settings?: FilterStringBuilderSettings) => {
  if (!filterManagers[id]) {
    filterManagers[id] = new FilterStringBuilder(settings)
  }

  const filterStringBuilder = filterManagers[id]

  filterStringBuilder.onChange = settings => {
    setAisFilter(settings.aisFilterString)
    setFilterList(settings.aisFilterList)
    setOptionalList(settings.optionalFilterList)
    setIsInDefaultState(settings.isInDefaultState)
    setActiveFilterCount(settings.activeFilterCount)
  }

  const filterHandler: FilterHandlerType = {
    initialize: (filters?: (string | number)[] | string) => {
      filterStringBuilder.initialize(filters)
    },
    isInitialized: () => {
      return filterStringBuilder.isInitialized
    },
    merge: (
      mergeFilters?: (string | number | undefined)[] | string,
      mergeAttributes?: string[] | undefined,
      replaceAttributes?: string[] | undefined,
    ) => {
      filterStringBuilder.merge(mergeFilters, mergeAttributes, replaceAttributes)
    },
    add: (
      attribute: string | undefined,
      labelsCandidate: string | number | undefined | (string | number)[],
    ) => {
      filterStringBuilder.add(attribute, labelsCandidate)
    },
    remove: (
      attribute: string | undefined,
      labelsCandidate: string | number | undefined | (string | number)[],
    ) => {
      filterStringBuilder.remove(attribute, labelsCandidate)
    },
    toggle: (
      attribute: string | undefined,
      labelsCandidate: string | number | undefined | (string | number)[],
    ) => {
      filterStringBuilder.update(attribute, labelsCandidate)
    },
    reset: (attributeLabel?: string) => {
      filterStringBuilder.reset(attributeLabel)
    },
    isActive: (attribute: string | undefined, label: string | number | undefined) =>
      filterStringBuilder.testFilterActive(attribute, label),
    getFilters: (attribute: string | undefined) =>
      filterStringBuilder.getFilterByAttribute(attribute),
    setDebug: (debugOn: boolean) => filterStringBuilder.setDebug(debugOn),
  }

  return { filterProperties, filterHandler }
}

export default FilterManager
